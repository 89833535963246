import { ROUTES } from 'constants/routes';
import APIEndPoints from '../api';

const getParams = (params: { [key: string]: string }) => {
    return Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&');
};

const getUpdatedParams = (params: { [key: string]: string }) => {
    const updatedParams = { ...params };
    const dataType = updatedParams.datatype;
    const label = updatedParams.label;
    updatedParams.datatype = label;
    const updatedDataType = dataType.split('(')[0].trim();
    updatedParams.label = dataType;
    const urlParams = Object.keys(updatedParams)
        .map((key) => `${key}=${updatedParams[key]}`)
        .join('&');
    return urlParams;
};

const GET_DASHBOARD_DATA = (params: { poolweek: string }) => {
    return `${APIEndPoints.BASE}${ROUTES.DASHBOARD}${ROUTES.EVENT_DETAILS}` + '?' + getParams(params);
};

const GET_EXCEPTION_DATA = (params: { [key: string]: string }) => {
    return `${APIEndPoints.BASE}transactiondata` + '?' + getUpdatedParams(params);
};

const GET_ELIGIBLE_POOLWEEK_DATA = (params: {poolweek: string}) => {
    return `${APIEndPoints.BASE}eligiblepoolweek?poolweek=${params.poolweek}`;
};

export { GET_DASHBOARD_DATA, GET_EXCEPTION_DATA,GET_ELIGIBLE_POOLWEEK_DATA };
