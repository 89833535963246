import React, { useState, useEffect } from 'react';
import DrcButton from '../Components/DrcButton';
import DrcMain from '../Components/DrcMain';
import DrcMediaQueries from '../Utilities/DrcMediaQueries';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useOktaAuth } from '@okta/okta-react';
import { makeStyles } from '@mui/styles';
import DrcAuthenticationUtilities from '../Utilities/DrcAuthenticationUtilities';
import { Translate } from 'react-localize-redux';
import { DuThemeUtilities } from '@driscollsinc/driscolls-react-utilities';
import { ReactComponent as ThreeLines } from '../Images/Black3lines.svg';
import { ReactComponent as Image401 } from '../Images/401.svg';

/**
 *@ignore
 */
const useStyles = (props) =>
    makeStyles((theme) => ({
        mainContainer: {
            margin: `104px auto 0  ${props.drawerOpen ? '240px' : '74px'}`,
            minHeight: 'calc(100vh - 105px)',
            maxWidth: 'none !important',
            borderRadius: '40px 0 0 40px',
            padding: '0px',
            background: '#e1e8e3'
        },
        labelStyle: {
            display: 'inline-block',
            margin: '20px 20px 0 20px',
            position: 'relative',
            top: '-15px'
        },
        flexContainer: {
            height: '100%',
            padding: '0',
            margin: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        flexRow: {
            width: '100%'
        },
        flexItem: {
            padding: '5px',
            margin: '10px',
            lineHeight: '20px',
            fontWeight: 'bold',
            fontSize: '2em',
            textAlign: 'center'
        },
        topContainer: {
            minWidth: '830px',
            textAlign: 'center',
            paddingTop: '43px',
            minHeight: '46vh',
            position: 'relative',
            background: '#e1e8e3'
        },
        bottomContainer: {
            minWidth: '830px',
            height: '50vh',
            background: '#fff',
            textAlign: 'center'
        },
        errorCode: {
            color: DuThemeUtilities.DefaultColors.primary.blue,
            fontSize: '7em',
            fontWeight: 'bold',
            paddingTop: '50px'
        },
        errorTitle: {
            fontSize: '3em',
            fontWeight: 'bold',
            display: 'inline-block'
        },
        icon: {
            position: 'relative',
            top: '-15px'
        },
        errorDescription: {
            fontSize: '18px'
        },
        textContainer: {
            width: '100%',
            margin: '0px auto',
            position: 'absolute',
            bottom: '10px'
        },
        linkButton: {
            background: DuThemeUtilities.DefaultColors.primary.blue,
            color: '#ffffff',
            borderRadius: '50px !important'
        },
        left: {
            color: 'red',
            float: 'left',
            animation: `$Blinker 1s linear infinite`
        },
        right: {
            color: 'red',
            float: 'right',
            animation: `$Blinker 1s linear infinite`
        },
        '@keyframes Blinker': {
            from: { opacity: 0 },
            to: { opacity: 1 }
        },
        imagecontainer: {
            position: 'absolute',
            width: '43%',
            left: '0',
            top: '115px',
            ['@media' + DrcMediaQueries.tablet]: {
                top: '330px',
                width: '40%'
            }
        },
        imagecontainerInner: {
            display: 'inline-block',
            verticalAlign: 'middle'
        },
        dummy: {
            height: '100%',
            display: 'inline-block',
            verticalAlign: 'middle'
        },
        image401: {
            width: '100%',
            height: 'auto'
        }
    }));
/**
 * @description The DrcPageNotAuthorized is a styled not authorized screen in the Driscolls format and is customizable
 * @class DrcPageNotAuthorized
 * @example
 * <DrcPageNotAuthorized />
 * @property {string} logOutUrl Logout url - default - '/Logout/'
 * @property {string} logInUrl LogIn url - default - '/'
 * @extends {React.Component} */
function DrcPageNotAuthorized(props) {
    const { authState, oktaAuth } = useOktaAuth();
    const classes = useStyles(props)();
    const history = useNavigate();

    const [sessionDetails, setSessionDetails] = useState({
        isChecking: true,
        hasChecked: false,
        hasSession: false,
        isLoggedIn: false
    });

    const [status, setStatus] = useState('ini');

    useEffect(() => {
        let temp = sessionDetails.isChecking ? (
            <Translate id="login.reauthorizing" />
        ) : sessionDetails.hasSession ? (
            <Translate id="login.authorizing" />
        ) : sessionDetails.isLoggedIn ? (
            <Translate id="login.loggedIn" />
        ) : (
            <Translate id="login.loggedOut" />
        );
        setStatus(temp);
    }, [sessionDetails]);

    const [isAuthenticated, setIsAuthenticated] = useState(null);

    /**
     *@ignore
     */
    useEffect(() => {
        let isOktaAuthenticated = authState?.isAuthenticated;

        if (isAuthenticated != isOktaAuthenticated) {
            setIsAuthenticated(isOktaAuthenticated);
        }

        if (!isOktaAuthenticated) {
            var credentials = DrcAuthenticationUtilities.GetUsernameAndPasswordFromQuery(window.location.search);

            if (credentials !== null) {
                DrcAuthenticationUtilities.SignIn(credentials.username, credentials.password);
            }
        }
    });

    /**
     *@ignore
     */
    useEffect(() => {
        if (props.setPageTitle) {
            props.setPageTitle('Not Authorized'); //Empty string makes page title go to default, which is good in this case.
        }
    }, []);

    /**
     *@ignore
     */
    useEffect(() => {
        if (oktaAuth && !authState?.isAuthenticated && !sessionDetails.hasChecked) {
            oktaAuth.session
                .exists()
                .then((session) => {
                    if (session) {
                        setSessionDetails({
                            isChecking: true,
                            hasChecked: false,
                            hasSession: true,
                            isLoggedIn: false
                        });
                        oktaAuth.token
                            .getWithoutPrompt({
                                responseType: ['id_token', 'token']
                            })
                            .then((result) => {
                                oktaAuth.tokenManager.setTokens(result.tokens);
                                setSessionDetails({
                                    isChecking: false,
                                    hasChecked: true,
                                    hasSession: true,
                                    isLoggedIn: true
                                });
                            })
                            .catch(() => {
                                setSessionDetails({
                                    isChecking: false,
                                    hasChecked: true,
                                    hasSession: false,
                                    isLoggedIn: false
                                });
                            });
                    } else {
                        setSessionDetails({
                            isChecking: false,
                            hasChecked: true,
                            hasSession: false,
                            isLoggedIn: false
                        });
                    }
                })
                .catch(() => {
                    setSessionDetails({
                        isChecking: false,
                        hasChecked: true,
                        hasSession: false,
                        isLoggedIn: false
                    });
                });
        }
    }, [authState, oktaAuth]);

    return (
        <DrcMain className={classes.mainContainer}>
            <Translate>
                {({ translate }) => (
                    <Helmet>
                        <title>{translate('login.notAuthorized', null, { renderInnerHtml: false })}</title>
                    </Helmet>
                )}
            </Translate>
            <div className={classes.flexContainer}>
                <div className={classes.flexRow}>
                    <div className={classes.topContainer}>
                        <div className={classes.textContainer}>
                            <p className={classes.errorCode}>401</p>
                            <span className={classes.icon}>
                                <ThreeLines />
                            </span>
                            <span className={classes.errorTitle}>
                                <Translate id="login.notAuthorized" />{' '}
                            </span>

                            <p className={classes.errorDescription}>
                                <Translate id="login.description" />
                            </p>
                        </div>
                        <div className={classes.imagecontainer}>
                            <div className={classes.dummy}></div>
                            <div className={classes.imagecontainerInner}>
                                <span className={classes.right}>{status}</span>
                                <Image401 className={classes.image401} />
                                <span className={classes.left}>{status}</span>
                            </div>
                        </div>
                    </div>

                    <div className={classes.bottomContainer}>
                        {isAuthenticated ? (
                            <DrcButton
                                onClick={() => {
                                    history.push(props.logOutUrl || '/LogOut/');
                                }}
                                className={classes.linkButton}
                                isPrimary={false}
                                isSecondary={true}
                                size="large"
                            >
                                <Translate id="login.logout" />
                            </DrcButton>
                        ) : (
                            <DrcButton
                                onClick={() => {
                                    history.push(props.logInUrl || '/');
                                }}
                                className={classes.linkButton}
                                isPrimary={false}
                                isSecondary={true}
                                size="large"
                            >
                                <Translate id="login.login" />
                            </DrcButton>
                        )}
                    </div>
                </div>
            </div>
        </DrcMain>
    );
}

export default DrcPageNotAuthorized;
