import { ExceptionsPage, syncTranslations } from '@driscollsinc/ggs-dashboard-module-ui';
import { withOktaAuth } from '@okta/okta-react';
import { setPageTitleAction } from 'actions/actions';
import { setSettlementWorkbenchData } from 'actions/settlementWorkbenchAction';
import DrcWrapper from 'components/DrcWrapper';
import Stepper from 'components/Stepper';
import useDashboard from 'hooks/v2/useDashboard';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { withRouter } from 'routes/withRouter';
import DuExcelUtilities from 'utils/excelUtility';
import { getLanguage } from 'utils/helper';

function Exceptions(props) {
    const [exceptionAttributes, setExceptionAttributes] = useState([]);
    const[data, setData] = useState([]);
    const { getExceptions } = useDashboard(props.oktaAuth);
    const { i18n } = useTranslation();
    const [params] = useSearchParams();
    const language = localStorage.getItem('GGS_UserLang');

    useEffect(() => {
        syncTranslations(getLanguage(language));
    }, [language]);

    const exportAsExcel = (data) => {
        const excelColumns = exceptionAttributes.map((col) => ({
            key: col.accessorKey,
            name: col.accessorKey
        }))
        DuExcelUtilities.Write("GGS- Data.xlsx", excelColumns, data )
    }

    const handleFileExport = async () => {
    // TODO: If entire data is needed:  const data = await getExceptions({ limit: totalCount, offset:'0', ...getSearchParams() }, searchText);
       exportAsExcel(data);
    }
    //TODO: if entire data is needed: const getSearchParams = (): { [key: string]: string } => {
    //     let entries = {};
    //     params.forEach((value, key) => {
    //       entries = { ...entries, [key]: value };
    //     });
    //     return entries;
    //   };

    return (
        <DrcWrapper>
            <Stepper page={params.get('Type')}></Stepper>
            <ExceptionsPage
                exceptionAttributes={exceptionAttributes}
                fetchEntries={async (page, searchString, params) => {
                    const data = await getExceptions({ limit: 100, offset: (page - 1) * 100, ...params }, searchString);
                    setExceptionAttributes(data.columns);
                    setData((currData) => ([...currData, ...data.data]));
                    return data;
                }}
                handleFileExport={handleFileExport}
            />
        </DrcWrapper>
    );
}

const mapStateToProps = ({ settlementWorkbenchReducer, rootReducer }) => ({
    total: settlementWorkbenchReducer.totalRecords,
    pageTitle: rootReducer.pageTitle,
    records: settlementWorkbenchReducer.records
});

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setSettlementWorkbenchData: (data) => dispatch(setSettlementWorkbenchData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withRouter(Exceptions)));
