import { useState } from 'react';
import { GET_PRODUCING_AREA, GET_GROWER, GET_RANCH, GET_ITEM } from 'services/v2/AddRowAPI';
import { getCall, postCall } from 'services/v2/Services';
import useGetApiTokens from './useGetApiTokens';

const ITEM_TYPE_FRUIT: string = 'fruit';
const ACTIVE: number = 1;

const useAddRowFields = (oktaAuth: any) => {
    const { getTokenAndId } = useGetApiTokens();
    const [producingAreaError, setProducingAreaError] = useState(null);
    const [producingAreaLoading, setProducingAreaLoading] = useState(false);
    const [producingArea, setProducingArea] = useState([] as any[]);

    const [growerError, setGrowerError] = useState(null);
    const [growerLoading, setGrowerLoading] = useState(false);
    const [grower, setGrower] = useState([] as any[]);

    const [ranchError, setRanchError] = useState(null);
    const [ranchLoading, setRanchLoading] = useState(false);
    const [ranch, setRanch] = useState([] as any[]);

    const [itemError, setItemError] = useState(null);
    const [itemLoading, setItemLoading] = useState(false);
    const [item, setItem] = useState([] as any[]);

    const getProducingArea = async (pagesize, startpage, berryType, fieldType) => {
        setProducingAreaLoading(true);
        const { token } = await getTokenAndId(oktaAuth);
        try {
            const response = await getCall(GET_PRODUCING_AREA(pagesize, startpage, ACTIVE, berryType, fieldType), token, {});
            if (response && response.errors && response.errors.length > 0) {
                throw response.errors[0];
            } else {
                setProducingArea(response.display.Data);
            }
        } catch (err) {
            setProducingAreaError(err);
        } finally {
            setProducingAreaLoading(false);
        }
    };

    const getGrower = async (pagesize, startpage, data) => {
        setGrowerLoading(true);
        const { token } = await getTokenAndId(oktaAuth);
        try {
            const response = await postCall(GET_GROWER(pagesize, startpage, ACTIVE), token, {}, data);
            if (response && response.errors && response.errors.length > 0) {
                throw response.errors[0];
            } else {
                setGrower(response.display.Data);
            }
        } catch (err) {
            setGrowerError(err);
        } finally {
            setGrowerLoading(false);
        }
    };

    const getRanch = async (pagesize, startpage, data) => {
        setRanchLoading(true);
        const { token } = await getTokenAndId(oktaAuth);
        try {
            const response = await postCall(GET_RANCH(pagesize, startpage, ACTIVE), token, {}, data);
            if (response && response.errors && response.errors.length > 0) {
                throw response.errors[0];
            } else {
                setRanch(response.display.Data);
            }
        } catch (err) {
            setRanchError(err);
        } finally {
            setRanchLoading(false);
        }
    };

    const getItem = async (pagesize, startpage, data, type = ITEM_TYPE_FRUIT) => {
        setItemLoading(true);
        const { token } = await getTokenAndId(oktaAuth);
        try {
            const response = await postCall(GET_ITEM(pagesize, startpage, type, ACTIVE), token, {}, data);
            if (response && response.errors && response.errors.length > 0) {
                throw response.errors[0];
            } else {
                setItem(response.display.Data);
            }
        } catch (err) {
            setItemError(err);
        } finally {
            setItemLoading(false);
        }
    };

    const resetDependency = (optionName: 'producingArea' | 'grower' | 'ranch' | 'item') => {
        switch (optionName) {
            case 'producingArea': setGrower([]);
            case 'grower': setRanch([]);
            case 'ranch': setItem([]);
            case 'item': setItem([]);
            default: return;
        }
    }

    return {
        producingArea,
        producingAreaError,
        producingAreaLoading,
        getProducingArea,
        grower,
        growerError,
        growerLoading,
        getGrower,
        ranch,
        ranchError,
        ranchLoading,
        getRanch,
        item,
        itemError,
        itemLoading,
        getItem,
        resetDependency
    };
};
export default useAddRowFields;
