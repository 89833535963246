import React from 'react';
import Image from 'react-image-webp';

/**
 * @description This DrcImage Component can be used to show a image.
 * @example <DrcImage title={require('../DriscollsLogo.png')} webp={require('../DriscollsLogo.webp')} src={image.src} alt={image.alt} />
 * @category Images
 * @tags image smart webp jpg png
 * @property {string} title This is the title displayed when hovering over the image.
 * @property {string} alt This is the alternative text that will be displayed when the image is loaded slowly or when the resource is not available.
 * @property {*} src This is the source-location for the image. This can be a require('sourcelocation') or a hardcoded string to a web url
 * @class DrcImage
 * @extends {React.Component}
 */
class DrcImage extends React.Component {
    /**
     * @ignore
     * @private
     * @return {*}
     * @memberof DrcImage
     */

    render() {
        return <Image title={this.props.title} src={this.props.src} webp={this.props.webp} className={this.props.className} style={this.props.style} alt={this.props.alt} />;
    }
}

export default DrcImage;
