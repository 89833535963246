/* eslint-disable no-undef */
import DuDateUtilities from 'drc/driscolls-react-components/Utilities/DuDateUtilities';

/**
 *@description Creates and excel file.
 * @param {string} filename name of the file.
 * @param {array} columns list of columns that the file will contain.
 * @param {array} data list of rows that the file will contain.
 * @param {string} [name="Driscoll's"]
 */
const writeExcelFile = function (filename, columns, data, name = "Driscoll's") {
    const dataToSave = [
        columns.map((c) => {
            if (typeof c.name === 'string') {
                return c.name;
            }
            return (c.name.props || [])['tipText'];
        })
    ];

    data.forEach((d) => {
        const row = columns.map((c) => d[c.key]);
        dataToSave.push(row);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(dataToSave);
    const new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, name);
    XLSX.writeFile(new_workbook, filename);
};

/**
 * @description write excel file with multiple Sheets.
 * @param {string} filename filename for the excell file
 * @param {array} dataObjectArray this is array of objects, each object should have pageName{string}(name for each page), columns{array}(column names), data{array}(actual data). Ex. [{pageName:'page1', columns:[{col1},{col2}], data:[{data1},{data2}]}, {pageName:'page2', columns:[{col1},{col2}], data:[{data1},{data2}]}]
 * @example writeExcelFileWithPages('samplefile.xlsx',dataObjectArray)
 * @example dataObjectArray=[{pageName:'page1', columns:[{col1},{col2}], data:[{data1},{data2}]}, {pageName:'page2', columns:[{col1},{col2}], data:[{data1},{data2}]}]
 **/
const writeExcelFileWithPages = function (filename, dataObjectArray) {
    const new_workbook = XLSX.utils.book_new();
    let worksheet;
    dataObjectArray.map((val) => {
        const dataToSave = [
            val.columns.map((c) => {
                if (typeof c.name === 'string') {
                    return c.name;
                }
                return (c.name.props || [])['tipText'];
            })
        ];

        val.data.forEach((d) => {
            const row = val.columns.map((c) => d[c.key]);
            dataToSave.push(row);
        });

        worksheet = XLSX.utils.aoa_to_sheet(dataToSave);
        XLSX.utils.book_append_sheet(new_workbook, worksheet, val.pageName);
    });

    XLSX.writeFile(new_workbook, filename);
};

/**
 *@description Read a file when drop event is executed.
 * @param {*} e element that contain the data.
 * @param {function} returnFunction callback that will be call when the file reding ends and receive as parameters the data fo the file.
 * @param {function} statusFunction callback that will be called to indicate the status of the file reading.
 */
const handleExcelDrop = function (e, returnFunction, statusFunction) {
    e.preventDefault();
    e.stopPropagation();

    const files = e.dataTransfer.files,
        f = files[0];

    readExcelFile(f, returnFunction, statusFunction);
};

/**
 *@description Add drop event to a element.
 * @param {*} element element to add drop event.
 * @param {function} returnFunction callback that will be call when the file reding ends and receive as parameters the data fo the file.
 * @param {function} statusFunction callback that will be called to indicate the status of the file reading.
 */
const createExcelDrop = function (element, returnFunction, statusFunction) {
    element.addEventListener(
        'drop',
        (e) => {
            handleExcelDrop(e, returnFunction, statusFunction);
        },
        false
    );
};

const handleFiles = function (fileList, returnFunction, statusFunction, read_opts) {
    if (!fileList || fileList.length <= 0) {
        return;
    }

    const f = fileList[0];
    readExcelFile(f, returnFunction, statusFunction, read_opts);
};

/**
 *@description Read an excel file.
 * @param {file} file file that will be read.
 * @param {function} returnFunction callback that will be call when the file reding ends and receive as parameters the data fo the file.
 * @param {function} statusFunction callback that will be called to indicate the status of the file reading.
 * @param {object} read_opts options to applied when the file is reading.
 */
const readExcelFile = async (file, returnFunction, statusFunction, read_opts) => {
    const startTime = new Date();
    const reader = new FileReader();
    read_opts = !read_opts ? { type: 'array' } : read_opts;
    const extension = file.name.lastIndexOf('.') > 0 ? file.name.substring(file.name.lastIndexOf('.') + 1) : '';

    if (extension !== 'xlsx' && extension !== 'xls' && extension !== 'xlsb') {
        returnFunction(null, {
            fileName: file.name,
            msElapsed: 0,
            timeElapsed: 'Failed',
            message: 'File Extension is not valid, please upload an excel document.'
        });
        return;
    }

    setStatus(statusFunction, -1, 'Starting to Open Excel File');

    reader.onload = function (e) {
        setStatus(statusFunction, -1, 'Excel File Open, ready to parse');
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, read_opts);
        setStatus(statusFunction, -1, 'Excel File Parsed');
        const endTime = new Date();

        returnFunction(workbook, {
            fileName: file.name,
            msElapsed: endTime - startTime,
            timeElapsed: DuDateUtilities.TimeSpanToString(startTime, endTime)
        });
    };

    reader.readAsArrayBuffer(file);
};

const readRows = async (workbook, sheetName, statusFunction, read_opts) => {
    read_opts = !read_opts ? { defval: '' } : read_opts;
    return new Promise((resolve) => {
        const rows = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], read_opts);
        resolve(rows);
    });
};

const setStatus = async (statusFunction, percentDone, message) => {
    const isIndeterminate = percentDone < 0;
    statusFunction({ isIndeterminate, percentDone, message });
};

/**
 *@description Return the row from excel data.
 * @param {array} data excel data.
 * @param {string} sheetName the name of the sheet that contains the row to obtain.
 * @param {number} row row number
 * @param {number} columnStart the first colum that the result will contain.
 * @param {number} columnEnd the last column that the result will contain.
 * @return {array} list of cells of the row that was found.
 */
const getRow = function (data, sheetName, columnStart, columnEnd, row = []) {
    const sheet = data[sheetName];

    if (!sheet) {
        return [];
    }

    const sheetKeys = Object.keys(sheet);

    for (let i = columnStart; i <= columnEnd; i++) {
        const cellKey = `${i}${row}`;
        if (!sheetKeys.includes(cellKey)) {
            continue;
        }

        row.push(sheet[cellKey]);
    }
};

/*
   TODO: Add following functions to support excel import more easily
   GetRows(data, sheetName, startRow, endRow, columnStart, columnEnd);
   GetRow(data, sheetName, row, columnStart, columnEnd);
   GetCell(data, sheetName, row, column);
   GetColumn(data, sheetName, columnStart, columnEnd, row);

   GetRowsWithTemplate(data, sheetName, template, startRow, endRow, columnStart, columnEnd);
   GetRowWithTemplate(data, sheetName, template, row, columnStart, columnEnd);
   GetColumnWithTemplate(data, sheetName, template, columnStart, columnEnd, row);
*/

const DuExcelUtilities = {
    Write: writeExcelFile,
    WriteWithPages: writeExcelFileWithPages,
    Read: readExcelFile,
    HandleFiles: handleFiles,
    CreateExcelDrop: createExcelDrop,
    GetRow: getRow,
    ReadRows: readRows
};

export default DuExcelUtilities;