// @ts-nocheck
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { CreateBusinessRules, ExceptionDialog, syncTranslations } from '@driscollsinc/ggs-business-rules-module-ui';
import { ButtonSize, ButtonVariant, DriscollsButton } from '@driscollsinc/style-guide-web2.0';
import { withOktaAuth } from '@okta/okta-react';
import { hideLoadingScreenAction, setPageTitleAction, showLoadingScreenAction, showToast } from 'actions/actions';
import { setSettlementWorkbenchData } from 'actions/settlementWorkbenchAction';
import DrcWrapper from 'components/DrcWrapper';
import Stepper from 'components/Stepper';
import { BusinessRuleActions } from 'constants/BusinessRuleActions';
import { ROUTES, getRoute } from 'constants/routes';
import { convertDateToIso } from 'data/constants';
import { EventConstants } from 'data/EventConstants';
import { BUSINESSRULE_CARD_FIELDS } from 'data/mock/BusinessRules';
import { DrcTranslate } from 'drc/driscolls-react-components';
import useAddRowFields from 'hooks/v2/useAddRowFields';
import useBusinessRules from 'hooks/v2/useBusinessRules';
import useGAAnalytics from 'hooks/v2/useGAAnalytics';
import useGetFilterData from 'hooks/v2/useGetFilterData';
import { NAMESPACE } from 'i18n';
import useLang from 'hooks/v2/useLang';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { withRouter } from 'routes/withRouter';
import { getLanguage, userBusinessUnit } from 'utils/helper';

const SAVE_ACTION_TYPE: string = 'save';
const CREATE_ACTION_TYPE: string = 'create';
const MODIFY_ACTION_TYPE: string = 'modify';
const SUBMIT_ACTION_TYPE: string = 'submit';

function CreateOrEditBusinessRule(props) {
    const { pathname } = useLocation();
    const { logEvent, logError } = useGAAnalytics();
    const { getFormattedProducingArea, getFormattedGrowers, getFormattedItems, getFormattedRanches, getFormatedBusinessRuleCardFields } =
        useGetFilterData();
    const { getTextTranslated } = useLang();
    const language = localStorage.getItem('GGS_UserLang');
    const [pricingPoolLoaded, setPricingPoolLoaded] = useState<boolean>(false);
    const [openExceptionDialog, setOpenExceptionDialog] = useState<boolean>(false);
    let { ruleId, ruleType } = useParams();
    const [ruleTypeId, setRuleTypeId] = useState('');
    const [pricingpoolId, setPricingpoolId] = useState(null | '');
    const navigate = useNavigate();
    const FormulaSeq = '1',
        FormulaType_AMOUNT = 'AmountPerUnit',
        FormulaType_PERCENT = 'Percent',
        CurrencyCode = 'USD';
    const [loading, setLoading] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isCopyMode, setIsCopyMode] = useState<boolean>(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(true);

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (hasUnsavedChanges) {
                event.preventDefault();
                event.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [hasUnsavedChanges]);

    useEffect(() => {
        syncTranslations(getLanguage(language));
    }, [language]);

    const [selectedRowItem, setSelectedRowItem] = useState({
        producingArea: [],
        grower: [],
        ranch: [],
        berryType: [],
        fieldType: [],
        brand: [],
        item: []
    });
    const [createRowDetails, setCreateRowDetails] = useState({
        producingAreaList: [],
        growerList: [],
        ranchList: [],
        berryTypeList: [],
        fieldTypeList: [],
        brandList: [],
        itemList: [],
        producingAreaSelectedList: [],
        growerSelectedList: [],
        ranchSelectedList: [],
        berryTypeSelectedList: [],
        fieldTypeSelectedList: [],
        brandSelectedList: [],
        itemSelectedList: [],
        showSummary: false
    });
    const [businessRuleCardFields, setBusinessRuleCardFields] = useState(BUSINESSRULE_CARD_FIELDS['default']);

    const {
        getBusinessRules,
        getLookupData,
        getRuleDetails,
        getApprovers,
        approverList,
        ruleDetailsError,
        ruleDetailsLoading,
        ruleDetails,
        submitRule,
        ruleCreateResponse,
        ruleCreateError,
        ruleCreateLoading,
        lookupData,
        lookupDataLoading,
        responseTime,
        businessRules,
        LOOKUP_TYPE_BERRYTYPE,
        LOOKUP_TYPE_BRAND,
        LOOKUP_TYPE_FIELDTYPE,
        approverGroupId
    } = useBusinessRules(props.oktaAuth);
    const pagesize = 1000,
        startpage = 1,
        ranchPagesize = 3500;
    const {
        producingArea,
        getProducingArea,
        grower,
        getGrower,
        ranch,
        getRanch,
        item,
        getItem,
        ranchLoading,
        growerLoading,
        producingAreaLoading,
        itemLoading
    } = useAddRowFields(props.oktaAuth);

    useEffect(() => {
        if (!pricingPoolLoaded) {
            getFormattedProducingArea(producingArea, setCreateRowDetails);
        }
    }, [producingArea]);

    useEffect(() => {
        getFormattedGrowers(grower, setCreateRowDetails);
    }, [grower]);

    useEffect(() => {
        getFormattedRanches(ranch, setCreateRowDetails);
    }, [ranch]);

    useEffect(() => {
        getFormattedItems(item, setCreateRowDetails);
    }, [item]);

    useEffect(() => {
        const editPath = ROUTES.BUSINESS_RULES_EDIT.slice('/')[0];
        getLookupData(LOOKUP_TYPE_BRAND);
        getLookupData(LOOKUP_TYPE_BERRYTYPE);
        getLookupData(LOOKUP_TYPE_FIELDTYPE);
        if (!pathname.includes(editPath)) {
            getProducingArea(pagesize, startpage);
            getGrower(pagesize, startpage, {});
            getRanch(ranchPagesize, startpage, {});
            getItem(pagesize, startpage, {});
        }
        getApprovers();
        getBusinessRules(1, 100, 1);
    }, []);

    useEffect(() => {
        if (businessRules && businessRules.length > 0) {
            let updatedFields = {
                ...businessRuleCardFields,
                packagingPool: {
                    ...businessRuleCardFields.packagingPool,
                    options: businessRules.map((brule) => {
                        return { value: brule.RuleId, label: `${brule.RuleId}- ${brule.RuleName}` };
                    })
                }
            };
            setBusinessRuleCardFields({ ...updatedFields });
        }
    }, [businessRules]);

    useEffect(() => {
        if (ruleDetailsError) {
            props.showToast(JSON.stringify(ruleDetailsError).slice(0, 200));
        }
    }, [ruleDetailsError]);

    useEffect(() => {
        if (lookupData) {
            setCreateRowDetails((prev) => {
                return {
                    ...prev,
                    berryTypeList: lookupData[LOOKUP_TYPE_BERRYTYPE],
                    fieldTypeList: lookupData[LOOKUP_TYPE_FIELDTYPE],
                    brandList: lookupData[LOOKUP_TYPE_BRAND]
                };
            });
        }
    }, [lookupData]);

    const updateBusinessRuleCardFields = (details) => {
        if (details && details.RuleTypeId) {
            let cardFields = getFormatedBusinessRuleCardFields(
                details,
                isEditMode,
                BUSINESSRULE_CARD_FIELDS[details.RuleTypeId] || BUSINESSRULE_CARD_FIELDS['default']
            );
            if (businessRules && businessRules.length > 0) {
                cardFields = {
                    ...cardFields,
                    packagingPool: {
                        ...cardFields.packagingPool,
                        options: businessRules.map((brule) => {
                            return { value: brule.RuleId, label: `${brule.RuleId}- ${brule.RuleName}` };
                        })
                    }
                };
            }
            setBusinessRuleCardFields({ ...cardFields });
        }
    };

    const loadFilterCriteria = () => {
        const producingAreaCodes = ruleDetails[0]?.RanchAssignment?.map((item) => item.ProducingAreaCode);
        const growerNbrs = ruleDetails[0]?.RanchAssignment?.map((item) => item.GrowerNbr);
        const berryTypes = [...new Set(ruleDetails[0]?.ItemAssignment?.map((item) => item.BerryType))];
        const brands = [...new Set(ruleDetails[0]?.ItemAssignment?.map((item) => item.Brand))];
        const packageTypes = [...new Set(ruleDetails[0]?.ItemAssignment?.map((item) => item.FieldType))];
        getProducingArea(pagesize, startpage, berryTypes?.length ? berryTypes : null, packageTypes?.length ? packageTypes : null);
        getGrower(pagesize, startpage, {
            ProducingAreaCodes: producingAreaCodes?.length ? producingAreaCodes : null
        });
        getRanch(ranchPagesize, startpage, {
            GrowerNbrs: growerNbrs?.length ? growerNbrs : null,
            ProducingAreaCodes: producingAreaCodes?.length ? producingAreaCodes : null
        });
        getItem(pagesize, startpage, {
            berryTypes: berryTypes?.length ? berryTypes : null,
            brands: brands?.length ? brands : null,
            packageTypes: packageTypes?.length ? packageTypes : null
        });
    };

    const formatRuleDetails = (details) => {
        let berryType;
        let fieldType;
        let brand;
        let item;
        if (details && details.RanchAssignment && details.RanchAssignment.length > 0) {
            let producingArea = details.RanchAssignment.map((item) => {
                return { value: item.ProducingAreaCode, label: `${item.ProducingAreaCode} - ${item.ProducingAreaName}` };
            });
            producingArea = [...new Map(producingArea.map((item) => [item['value'], item])).values()];
            let grower = details.RanchAssignment.map((item) => {
                return { value: item.GrowerNbr, label: `${item.GrowerNbr} - ${item.GrowerName}`, producingarea: item.ProducingAreaCode };
            });
            grower = [...new Map(grower.map((item) => [item['value'], item])).values()];
            let ranch = details.RanchAssignment.map((item) => {
                return { value: item.RanchNbr, label: `${item.RanchNbr} - ${item.RanchName}` };
            });
            ranch = [...new Map(ranch.map((item) => [item['value'], item])).values()];

            if (details.ItemAssignment && details.ItemAssignment.length > 0) {
                berryType = details.ItemAssignment.map((item) => {
                    return { value: item.BerryType, label: item.BerryType };
                });
                berryType = [...new Map(berryType.map((item) => [item['value'], item])).values()];
                fieldType = details.ItemAssignment.map((item) => {
                    return { value: item.FieldType, label: item.FieldType };
                });
                fieldType = [...new Map(fieldType.map((item) => [item['value'], item])).values()];
                brand = details.ItemAssignment.map((item) => {
                    return { value: item.Brand, label: item.Brand };
                });
                brand = [...new Map(brand.map((item) => [item['value'], item])).values()];
                item = details.ItemAssignment.map((item) => {
                    return { value: item.ItemNbr, label: `${item.ItemNbr} - ${item.ItemName}` };
                });
                item = [...new Map(item.map((item) => [item['value'], item])).values()];
            }
            setSelectedRowItem({
                producingArea,
                grower,
                ranch,
                berryType,
                fieldType,
                brand,
                item
            });

            setCreateRowDetails((prev) => {
                return {
                    ...prev,
                    producingAreaSelectedList: producingArea,
                    growerSelectedList: grower,
                    ranchSelectedList: ranch,
                    berryTypeSelectedList: berryType,
                    fieldTypeSelectedList: fieldType,
                    brandSelectedList: brand,
                    itemSelectedList: item
                };
            });
        }
    };

    useEffect(() => {
        if (ruleDetails && ruleDetails.length > 0) {
            if (!pricingPoolLoaded) {
                updateBusinessRuleCardFields(ruleDetails[0]);
                formatRuleDetails(ruleDetails[0]);
                setRuleTypeId(ruleDetails[0].RuleTypeId);
            } else {
                const details = ruleDetails[0];
                let berryType;
                let fieldType;
                let brand;
                let item;
                let producingArea = (ruleDetails[0].RanchAssignment || []).map((item) => {
                    return { value: item.ProducingAreaCode, label: `${item.ProducingAreaCode} - ${item.ProducingAreaName}` };
                });
                producingArea = [...new Map(producingArea.map((item) => [item['value'], item])).values()];
                let grower = (details.RanchAssignment || []).map((item) => {
                    return { value: item.GrowerNbr, label: `${item.GrowerNbr} - ${item.GrowerName}` };
                });
                grower = [...new Map(grower.map((item) => [item['value'], item])).values()];
                let ranch = (details.RanchAssignment || []).map((item) => {
                    return { value: item.RanchNbr, label: `${item.RanchNbr} - ${item.RanchName}` };
                });
                ranch = [...new Map(ranch.map((item) => [item['value'], item])).values()];

                if (details.ItemAssignment && details.ItemAssignment.length > 0) {
                    berryType = details.ItemAssignment.map((item) => {
                        return { value: item.BerryType, label: item.BerryType };
                    });
                    berryType = [...new Map(berryType.map((item) => [item['value'], item])).values()];
                    fieldType = details.ItemAssignment.map((item) => {
                        return { value: item.FieldType, label: item.FieldType };
                    });
                    fieldType = [...new Map(fieldType.map((item) => [item['value'], item])).values()];
                    brand = details.ItemAssignment.map((item) => {
                        return { value: item.Brand, label: item.Brand };
                    });
                    brand = [...new Map(brand.map((item) => [item['value'], item])).values()];
                    item = details.ItemAssignment.map((item) => {
                        return { value: item.ItemNbr, label: `${item.ItemNbr} - ${item.ItemName}` };
                    });
                    item = [...new Map(item.map((item) => [item['value'], item])).values()];
                }

                setCreateRowDetails((prev) => {
                    return {
                        ...prev,
                        producingAreaList: producingArea,
                        growerList: grower,
                        ranchLis: ranch,
                        berryTypeList: berryType,
                        fieldTypeList: fieldType,
                        brandList: brand,
                        itemList: item,
                        producingAreaSelectedList: producingArea,
                        growerSelectedList: grower,
                        ranchSelectedList: ranch,
                        berryTypeSelectedList: berryType,
                        fieldTypeSelectedList: fieldType,
                        brandSelectedList: brand,
                        itemSelectedList: item
                    };
                });
            }
            //Loading the Grower,Ranch,brand,berry type, fieldtype and items drop down items
            loadFilterCriteria();
        }
    }, [ruleDetails]);

    useEffect(() => {
        setCreateRowDetails((prev) => {
            return {
                ...prev,
                isRanchLoading: ranchLoading,
                isGrowerLoading: growerLoading
            };
        });
    }, [growerLoading, ranchLoading]);
    useEffect(() => {
        setLoading(ruleDetailsLoading);
    }, [ruleDetailsLoading]);

    useEffect(() => {
        // Reset the loading after businessRuleCardFields is upadted (on create businedd rule)
        setLoading(false);
    }, [businessRuleCardFields]);

    useEffect(() => {
        setLoading(ruleCreateLoading);
    }, [ruleCreateLoading]);

    useEffect(() => {
        if (ruleType) {
            setRuleTypeId(Number(ruleType));
        }
    }, [ruleType]);

    useEffect(() => {
        const isEditMode = matchPath(ROUTES.BUSINESS_RULES + ROUTES.BUSINESS_RULES_EDIT, pathname);
        const isCreateMode = matchPath(ROUTES.BUSINESS_RULES + ROUTES.BUSINESS_RULES_CREATE, pathname);
        const isCopyMode = matchPath(ROUTES.BUSINESS_RULES + ROUTES.BUSINESS_RULES_COPY, pathname);
        if (isCreateMode && ruleTypeId) {
            setIsEditMode(false);
            setLoading(true);
            let cardFields = BUSINESSRULE_CARD_FIELDS[Number(ruleTypeId)] || BUSINESSRULE_CARD_FIELDS['default'];
            setBusinessRuleCardFields({
                ...cardFields,
                ruleId: { ...cardFields.ruleId, visible: false },
                status: { ...cardFields.status, value: 'NEW', disabled: true }
            });
        } else if (isEditMode && ruleId) {
            setIsEditMode(true);
            getRuleDetails(ruleId);
        } else if (isCopyMode && ruleId) {
            setIsEditMode(false);
            setIsCopyMode(true);
            getRuleDetails(ruleId);
        }
    }, [ruleId, ruleTypeId]);

    useEffect(() => {
        if (ruleCreateError) {
            setOpenExceptionDialog(true);
        }
    }, [ruleCreateError]);

    useEffect(() => {
        if (ruleCreateResponse) {
            if (isEditMode) {
                props.showToast(ruleCreateResponse, true);
                setHasUnsavedChanges(false);
            } else {
                goBack();
            }
        }
    }, [ruleCreateResponse]);

    const onRowSelectedFieldsChange = (rowFields) => {
        const some = rowFields.grower.filter((item) => rowFields.producingArea.find((producingArea) => producingArea.value === item.producingarea));
        setCreateRowDetails((prev) => {
            return {
                ...prev,
                producingAreaSelectedList: rowFields.producingArea,
                growerSelectedList: rowFields.grower.filter((item) =>
                    rowFields.producingArea.find((producingArea) => producingArea.value === item.producingarea)
                ),
                ranchSelectedList: rowFields.ranch,
                berryTypeSelectedList: rowFields.berryType,
                fieldTypeSelectedList: rowFields.fieldType,
                brandSelectedList: rowFields.brand,
                itemSelectedList: rowFields.item
            };
        });
    };

    const onRowFieldChange = async (filed, data) => {
        const producingAreaCodes = data.producingArea.map((item) => item.value);
        const growerNbrs = data.grower.map((item) => item.value);
        const berryTypes = data.berryType.map((item) => item.value);
        const brands = data.brand.map((item) => item.value);
        const packageTypes = data.fieldType.map((item) => item.value);
        const some = data.grower.filter((item) => data.producingArea.find((producingArea) => producingArea.value === item.producingarea));
        switch (filed) {
            case 'producingArea':
                if (producingAreaCodes.length) {
                    getGrower(pagesize, startpage, {
                        ProducingAreaCodes: producingAreaCodes.length ? producingAreaCodes : null,
                        BerryTypes: berryTypes.length ? berryTypes : null,
                        FieldTypes: packageTypes.length ? packageTypes : null
                    });
                    setCreateRowDetails((prev) => {
                        return {
                            ...prev,
                            growerSelectedList: some,
                            producingAreaSelectedList: data.producingArea
                        };
                    });
                } else {
                    setCreateRowDetails((prev) => {
                        return {
                            ...prev,
                            growerList: [],
                            growerSelectedList: [],
                            ranchList: [],
                            ranchSelectedList: [],
                            producingAreaSelectedList: data.producingArea
                        };
                    });
                }

                break;
            case 'grower':
                if (growerNbrs.length) {
                    setCreateRowDetails((prev) => {
                        return {
                            ...prev,
                            growerSelectedList: data.grower
                        };
                    });
                    getRanch(ranchPagesize, startpage, {
                        BerryTypes: berryTypes.length ? berryTypes : null,
                        FieldTypes: packageTypes.length ? packageTypes : null,
                        GrowerNbrs: growerNbrs.length ? growerNbrs : null,
                        ProducingAreaCodes: producingAreaCodes.length ? producingAreaCodes : null
                    });
                } else {
                    setCreateRowDetails((prev) => {
                        return {
                            ...prev,
                            ranchList: [],
                            ranchSelectedList: []
                        };
                    });
                }
                break;
            case 'berryType':
            case 'brand':
            case 'fieldType':
                getProducingArea(pagesize, startpage, berryTypes?.length ? berryTypes : null, packageTypes?.length ? packageTypes : null);
                getItem(pagesize, startpage, {
                    berryTypes: berryTypes.length ? berryTypes : null,
                    brands: brands.length ? brands : null,
                    packageTypes: packageTypes.length ? packageTypes : null
                });
                break;
            default:
                break;
        }
    };

    const onSubmit = async (type, businessRulesDetails, selectedRowDetails) => {
        let token = await props.oktaAuth.getAccessToken();
        let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
        let loggedInUserName = DuAuthenticationUtilities.GetUserId(token);
        const ranchesList = selectedRowDetails?.ranch ? selectedRowDetails.ranch.map((item) => item.value) : [];
        const itemsList = selectedRowDetails?.item ? selectedRowDetails.item.map((item) => item.value) : [];
        let data = {
            RuleTypeId: ruleTypeId,
            BusinessUnit: userBusinessUnit(),
            RuleName: businessRulesDetails.description,
            Status: businessRulesDetails.status,
            StartDate: businessRulesDetails.startDate ? convertDateToIso(new Date(businessRulesDetails.startDate)) : '',
            EndDate: businessRulesDetails.endDate ? convertDateToIso(new Date(businessRulesDetails.endDate)) : '',
            UOM: businessRulesDetails.uom,
            PFQFlag: businessRulesDetails.pfq,
            ...(businessRulesDetails.pfq?.toUpperCase() === 'Y' && { PricingPoolId: pricingpoolId }),
            Formula: [
                {
                    ...(type !== SUBMIT_ACTION_TYPE && !isEditMode && { CreatedBy: loggedInUser }),
                    ...(type !== SUBMIT_ACTION_TYPE && !isEditMode && { CreatedDateTime: new Date().toISOString() }),
                    ...(type !== SUBMIT_ACTION_TYPE && isEditMode && { ModifiedBy: loggedInUser }),
                    ...(type !== SUBMIT_ACTION_TYPE && isEditMode && { ModifiedDateTime: new Date().toISOString() }),
                    FormulaSeq: FormulaSeq,
                    FormulaType: FormulaType_AMOUNT,
                    CurrencyCode: CurrencyCode,
                    FormulaValue:
                        businessRulesDetails.defaultPrice ||
                        businessRulesDetails.ruleValue ||
                        businessRulesDetails.advanceAmount ||
                        businessRulesDetails.defaultPackagingCost ||
                        businessRulesDetails.defaultRelativeScore ||
                        businessRulesDetails.sharePercent ||
                        businessRulesDetails.harvestCost
                },
                businessRulesDetails.financePercent && {
                    ...(type === SUBMIT_ACTION_TYPE && !isEditMode && { CreatedBy: loggedInUser }),
                    ...(type === SUBMIT_ACTION_TYPE && !isEditMode && { CreatedDateTime: new Date().toISOString() }),
                    ...(type !== SUBMIT_ACTION_TYPE && isEditMode && { ModifiedBy: loggedInUser }),
                    ...(type !== SUBMIT_ACTION_TYPE && isEditMode && { ModifiedDateTime: new Date().toISOString() }),
                    FormulaSeq: FormulaSeq,
                    FormulaType: FormulaType_PERCENT,
                    CurrencyCode: CurrencyCode,
                    FormulaValue: businessRulesDetails.financePercent
                }
            ].filter((item) => {
                return item && item.FormulaSeq;
            }),
            Assignment: { ranches: ranchesList, items: itemsList }
        };
        if (isEditMode) {
            data = {
                ...data,
                RuleId: ruleId
            };
        }
        // Set the saved data so won't get lost on relaod
        updateBusinessRuleCardFields({ ...data });
        if (type !== SUBMIT_ACTION_TYPE) {
            if (isEditMode) {
                submitRule({
                    Action: BusinessRuleActions.MODIFY,
                    BusinessRule: { ...data, ModifiedBy: loggedInUser, ModifiedDateTime: new Date().toISOString() }
                });
            } else {
                submitRule({
                    Action: BusinessRuleActions.CREATE,
                    BusinessRule: { ...data, CreatedBy: loggedInUser, CreatedDateTime: new Date().toISOString() }
                });
            }
        } else {
            submitRule({
                Action: BusinessRuleActions.REQUEST4APPROVAL,
                BusinessRule: { ...data, ModifiedBy: loggedInUser, ModifiedDateTime: new Date().toISOString() },
                UserName: type === SUBMIT_ACTION_TYPE ? loggedInUserName : '',
                UserEmail: type === SUBMIT_ACTION_TYPE ? loggedInUser : '',
                WebURL: type === SUBMIT_ACTION_TYPE ? ROUTES.BUSINESS_RULES + '/' + ROUTES.BUSINESS_RULES_APPROVE + '/' : '',
                GroupId: type === SUBMIT_ACTION_TYPE ? approverGroupId : ''
            });
        }
    };

    const onDelete = () => {
        // Delete rule
        let data = {
            Action: BusinessRuleActions.DELETE,
            BusinessRule: {
                BusinessUnit: userBusinessUnit(),
                RuleId: ruleId,
                RuleTypeId: ruleTypeId
            }
        };
        submitRule(data);
        goBack();
    };

    const goBack = () => {
        navigate(getRoute(ROUTES.BUSINESS_RULES_LIST, ruleType || ruleTypeId));
    };

    const onPricingPoolChange = (pricingpoolId: string) => {
        setPricingpoolId(pricingpoolId);
        setPricingPoolLoaded(true);
        getRuleDetails(pricingpoolId);
    };

    const viewHistory = () => {
        logEvent(EventConstants.BUSINESS_RULE_VIEW_HISTORY, { ruleId });
        navigate(getRoute(ROUTES.BUSINESS_RULES_HISTORY, ruleId));
    };
    return (
        <DrcWrapper>
            <Stepper
                page={isEditMode ? 'Edit Business Rule' : isCopyMode ? `Create New Business Rule (Copy - ${ruleId})` : 'Create New Business Rule'}
                showBackButton={true}
                goBack={goBack}
            >
                <DriscollsButton buttonSize={ButtonSize.SMALL} variant={ButtonVariant.TEXT} disabled={ruleId ? false : true} onClick={() => viewHistory()}>
                    View History
                </DriscollsButton>
            </Stepper>

            <div style={{ padding: '0 20px' }}>
                <CreateBusinessRules
                    businessRuleCardFields={businessRuleCardFields}
                    createRowDetails={createRowDetails}
                    selectedRowItem={selectedRowItem}
                    onSubmit={onSubmit}
                    isLoading={loading || ranchLoading || growerLoading || producingAreaLoading || itemLoading}
                    showDeleteButton={isEditMode || isCopyMode ? true : false}
                    deleteRow={() => onDelete()}
                    addRowButtonLabel={
                        isEditMode || isCopyMode
                            ? getTextTranslated('Edit Row', NAMESPACE.BUSINESSRULES)
                            : getTextTranslated('Add New Row', NAMESPACE.BUSINESSRULES)
                    }
                    modalButtonLabel={isEditMode || isCopyMode ? 'Update' : 'Create'}
                    onRowSelectedFieldsChange={onRowSelectedFieldsChange}
                    onRowFieldChange={onRowFieldChange}
                    approverOptions={approverList}
                    onPricingPoolChange={onPricingPoolChange}
                />
                {isEditMode && (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right', marginTop: '5px' }}>
                        <DrcTranslate>{'Results loaded in'}</DrcTranslate> {`${responseTime.toFixed(1)} s`}
                    </div>
                )}
            </div>
            <ExceptionDialog
                isOpen={openExceptionDialog}
                onClose={() => setOpenExceptionDialog(false)}
                message={`${JSON.stringify(ruleCreateError)}`}
            />
        </DrcWrapper>
    );
}

const mapStateToProps = ({ settlementWorkbenchReducer, rootReducer }) => ({
    total: settlementWorkbenchReducer.totalRecords,
    showLoadingScreen: rootReducer.showLoadingScreen,
    loadingScreenMessage: rootReducer.loadingScreenMessage,
    pageTitle: rootReducer.pageTitle,
    records: settlementWorkbenchReducer.records,
    showToast: rootReducer.showToast
});

const mapDispatchToProps = (dispatch) => ({
    showLoadingScreenAction: () => dispatch(showLoadingScreenAction()),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction()),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    showToast: (message, type) => dispatch(showToast(message, type)),
    setSettlementWorkbenchData: (data) => dispatch(setSettlementWorkbenchData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withRouter(CreateOrEditBusinessRule)));
