// @ts-nocheck
import { withStyles } from '@mui/styles';
import { DrcPageNotFound, DrcSecureGroupRoute } from 'drc/driscolls-react-components/index';
import CreateOrEditBusinessRule from 'pages/v2/CreateOrEditBusinessRule/CreateOrEditBusinessRule';
import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import {
    addInfo,
    hideCommonDialogAction,
    hideLoadingScreenAction,
    serviceWorkerUpdate,
    setCommonDialogAction,
    setPageTitleAction,
    showLoadingScreenAction,
    showReportBackLinkAction,
    showToast
} from '../actions/actions';
import { setInitializeRedirectUrl, setMasterDataInitialized, setUserLang } from '../actions/MasterActions';
import Dashboard from '../pages/v2/Dashboard/Dashboard';
import BusinessRules from 'pages/v2/BusinessRules/BusinessRules';
import Exceptions from 'pages/v2/Dashboard/Exceptions';
import WorkbenchOverview from 'pages/v2/Workbench/WorkbenchOverview';
import WorkbenchDetails from 'pages/v2/Workbench/WorkbenchDetails';
import { BreakdownPage } from '@driscollsinc/ggs-workbench-module-ui';
import WorkbenchBreakdown from 'pages/v2/Workbench/WorkbenchBreakdown';
import { ROUTES } from 'constants/routes';
import BusinessRuleRoutes from './BusinessRuleRoutes';
import WorkbenchApproval from 'pages/v2/Workbench/WorkbenchApproval';
import SettlementWorkspaceRoutes from './SettlementWorkspaceRoutes';
import DrcPageNotFoundV2 from 'drc/driscolls-react-components/Pages/DrcPageNotFoundV2';

const allGroups = [...window['config'].OKTA_GGS_ADMIN_GROUPS, ...window['config'].OKTA_USER_GROUPS, ...window['config'].OKTA_ADMIN_GROUPS];

var styles = (theme) => ({
    drawerIcon: {
        width: 20,
        height: 20
    },
    legendClassName: {
        fontFamily: 'Rubik'
    }
});

class V2Routes extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Routes>
                <Route path="/Dashboard/">
                    <Route
                        index
                        path=""
                        element={
                            <DrcSecureGroupRoute groupsAllowed={allGroups}>
                                <Dashboard />
                            </DrcSecureGroupRoute>
                        }
                    />
                    <Route
                        path="Exceptions"
                        element={
                            <DrcSecureGroupRoute groupsAllowed={allGroups}>
                                <Exceptions />
                            </DrcSecureGroupRoute>
                        }
                    />
                </Route>
                <Route
                    path={`${ROUTES.SETTLEMENT_WORKSPACE}${ROUTES.SEPARATOR}${ROUTES.WILDCARD}`}
                    element={
                        <DrcSecureGroupRoute groupsAllowed={allGroups}>
                            <SettlementWorkspaceRoutes />
                        </DrcSecureGroupRoute>
                    }
                />
                <Route
                    path={`${ROUTES.BUSINESS_RULES}${ROUTES.SEPARATOR}${ROUTES.WILDCARD}`}
                    element={
                        <DrcSecureGroupRoute groupsAllowed={allGroups}>
                            <BusinessRuleRoutes />
                        </DrcSecureGroupRoute>
                    }
                />
                <Route path={ROUTES.WILDCARD} element={<DrcPageNotFoundV2 />} />
            </Routes>
        );
    }
}

function mapStateToProps(state) {
    return {
        showReportBackLink: state.rootReducer.showReportBackLink,
        showLoadingScreen: state.rootReducer.showLoadingScreen,
        loadingScreenMessage: state.rootReducer.loadingScreenMessage,
        commonDialog: state.rootReducer.commonDialog,
        pageTitle: state.rootReducer.pageTitle,
        isInitialized: state.masterReducer.isInitialized,
        userLang: state.masterReducer.userLang,
        toastMessage: state.rootReducer.toastMessage,
        isSuccess: state.rootReducer.isSuccess,
        serviceWorkerRegistration: state.rootReducer.serviceWorkerRegistration,
        serviceWorkerUpdated: state.rootReducer.serviceWorkerUpdated
    };
}

const mapDispatchToProps = (dispatch) => ({
    hideCommonDialog: () => dispatch(hideCommonDialogAction()),
    showReportBackLinkAction: () => dispatch(showReportBackLinkAction()),
    showLoadingScreenAction: () => dispatch(showLoadingScreenAction()),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction()),
    addInfo: (info) => dispatch(addInfo(info)),
    setUserLang: (lang) => dispatch(setUserLang(lang)),
    setMasterDataInitialized: (isInitialized) => dispatch(setMasterDataInitialized(isInitialized)),
    setInitializeRedirectUrl: (redirectUrl) => dispatch(setInitializeRedirectUrl(redirectUrl)),
    setCommonDialogAction: (title, errors) => dispatch(setCommonDialogAction(title, errors)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    showToast: (message, isSuccess) => dispatch(showToast(message, isSuccess)),
    serviceWorkerUpdate: () => dispatch(serviceWorkerUpdate())
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(V2Routes)));
