// @ts-nocheck
import { withStyles } from '@mui/styles';
import { ROUTES } from 'constants/routes';
import { DrcSecureGroupRoute } from 'drc/driscolls-react-components/index';
import BusinessRules from 'pages/v2/BusinessRules/BusinessRules';
import BusinessRulesHistoryView from 'pages/v2/BusinessRules/BusinessRulesHistoryView';
import CreateOrEditBusinessRule from 'pages/v2/CreateOrEditBusinessRule/CreateOrEditBusinessRule';
import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { setInitializeRedirectUrl, setMasterDataInitialized, setUserLang } from '../actions/MasterActions';
import {
    addInfo,
    hideCommonDialogAction,
    hideLoadingScreenAction,
    serviceWorkerUpdate,
    setCommonDialogAction,
    setPageTitleAction,
    showLoadingScreenAction,
    showReportBackLinkAction,
    showToast
} from '../actions/actions';
import BusinessRulesApprovalView from 'pages/v2/BusinessRulesApproval/BusinessRulesApprovalView';
import DrcPageNotFoundV2 from 'drc/driscolls-react-components/Pages/DrcPageNotFoundV2';

const allGroups = [...window['config'].OKTA_GGS_ADMIN_GROUPS, ...window['config'].OKTA_USER_GROUPS, ...window['config'].OKTA_ADMIN_GROUPS];

var styles = (theme) => ({
    drawerIcon: {
        width: 20,
        height: 20
    },
    legendClassName: {
        fontFamily: 'Rubik'
    }
});

class BusinessRuleRoutes extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Routes>
                <Route
                    path=""
                    element={
                        <DrcSecureGroupRoute groupsAllowed={allGroups}>
                            <BusinessRules />
                        </DrcSecureGroupRoute>
                    }
                />
                <Route
                    path={ROUTES.BUSINESS_RULES_LIST}
                    element={
                        <DrcSecureGroupRoute groupsAllowed={allGroups}>
                            <BusinessRules />
                        </DrcSecureGroupRoute>
                    }
                />
                <Route
                    path={ROUTES.BUSINESS_RULES_CREATE}
                    element={
                        <DrcSecureGroupRoute groupsAllowed={allGroups}>
                            <CreateOrEditBusinessRule />
                        </DrcSecureGroupRoute>
                    }
                />
                <Route
                    path={ROUTES.BUSINESS_RULES_COPY}
                    element={
                        <DrcSecureGroupRoute groupsAllowed={allGroups}>
                            <CreateOrEditBusinessRule />
                        </DrcSecureGroupRoute>
                    }
                />
                <Route
                    path={ROUTES.BUSINESS_RULES_EDIT}
                    element={
                        <DrcSecureGroupRoute groupsAllowed={allGroups}>
                            <CreateOrEditBusinessRule />
                        </DrcSecureGroupRoute>
                    }
                />
                   <Route
                    path={ROUTES.BUSINESS_RULES_APPROVE}
                    element={
                        <DrcSecureGroupRoute groupsAllowed={allGroups}>
                            <BusinessRulesApprovalView />
                        </DrcSecureGroupRoute>
                            }
                     />
                <Route
                    path={ROUTES.BUSINESS_RULES_HISTORY}
                    element={
                        <DrcSecureGroupRoute groupsAllowed={allGroups}>
                            <BusinessRulesHistoryView />
                        </DrcSecureGroupRoute>
                    }
                />
                 <Route path={ROUTES.WILDCARD} element={<DrcPageNotFoundV2 />} />
            </Routes>
        );
    }
}

function mapStateToProps(state) {
    return {
        showReportBackLink: state.rootReducer.showReportBackLink,
        showLoadingScreen: state.rootReducer.showLoadingScreen,
        loadingScreenMessage: state.rootReducer.loadingScreenMessage,
        commonDialog: state.rootReducer.commonDialog,
        pageTitle: state.rootReducer.pageTitle,
        isInitialized: state.masterReducer.isInitialized,
        userLang: state.masterReducer.userLang,
        toastMessage: state.rootReducer.toastMessage,
        isSuccess: state.rootReducer.isSuccess,
        serviceWorkerRegistration: state.rootReducer.serviceWorkerRegistration,
        serviceWorkerUpdated: state.rootReducer.serviceWorkerUpdated
    };
}

const mapDispatchToProps = (dispatch) => ({
    hideCommonDialog: () => dispatch(hideCommonDialogAction()),
    showReportBackLinkAction: () => dispatch(showReportBackLinkAction()),
    showLoadingScreenAction: () => dispatch(showLoadingScreenAction()),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction()),
    addInfo: (info) => dispatch(addInfo(info)),
    setUserLang: (lang) => dispatch(setUserLang(lang)),
    setMasterDataInitialized: (isInitialized) => dispatch(setMasterDataInitialized(isInitialized)),
    setInitializeRedirectUrl: (redirectUrl) => dispatch(setInitializeRedirectUrl(redirectUrl)),
    setCommonDialogAction: (title, errors) => dispatch(setCommonDialogAction(title, errors)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    showToast: (message, isSuccess) => dispatch(showToast(message, isSuccess)),
    serviceWorkerUpdate: () => dispatch(serviceWorkerUpdate())
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(BusinessRuleRoutes)));
