/**
 *@ignore
 */
 export default (theme) => ({
    label: {
        float: 'right',
        [theme.darkTheme]: {
            color: '#aaa'
        },
        fontSize: '14px'
    },
    root: {
        width: '100%',
        '& .p-column-title': {
            '& span': {
                fontWeight: '600'
            },
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            float: 'left'
        },
        '& .p-datatable-scrollable-wrapper': {
            overflowX: 'auto'
        }
    },
    inputEditor: {
        height: '90%',
        border: '1px solid #D1CBCB',
        [theme.darkTheme]: {
            backgroundColor: '#404040',
            border: '1px solid #5d5b5b',
            color: '#DDD'
        }
    },
    select: {
        '& .p-dropdown-items-wrapper': {
            backgroundColor: theme.light.primary,
            border: '1px solid ' + theme.light.accent.brightness(10),
            // overflow: 'visible',
            '&:hover': {
                border: '1px solid ' + theme.dark.accent.brightness(10)
            }
        },
        '& .p-dropdown-items': {
            padding: 0
        },
        '& .p-dropdown-item': {
            '&:hover': {
                backgroundColor: theme.light.secondary,
                color: theme.light.text.primary
            },
            padding: 5
        },
        [theme.darkTheme]: {
            '& .p-dropdown-items-wrapper': {
                backgroundColor: theme.dark.primary,
                border: '1px solid ' + theme.dark.text.primary
            },
            '& .p-dropdown-item': {
                '&:hover': {
                    backgroundColor: theme.dark.secondary,
                    color: theme.dark.text.primary
                }
            }
        }
    },
    gridCompact: {
        '& .p-datatable-tbody tr td': {
            height: 'inherit !important',
        }
    },
    grid: {
        '& .p-datatable-tbody tr td': {
            height: 35,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            display: 'inline-block',
            lineHeight: '27px'
        },
        '& .p-datatable-emptymessage td': {
            paddingTop: '10px !important',
            paddingBottom: '10px !important',
            textAlign: 'center'
        },
        '& .p-datatable .p-datatable-thead>tr>th, .p-datatable .p-datatable-tbody>tr>td, .p-datatable .p-datatable-tfoot>tr>td': {
            padding: 4
        },
        '& .p-dropdown.p-component.p-dropdown-clearable': {
            '&:hover': {
                backgroundColor: theme.light.secondary,
                color: theme.light.text.primary,
                border: '1px solid ' + theme.light.accent.brightness(10)
            },
            borderBottom: '1px solid rgb(221, 221, 221)',
            borderTop: '1px solid rgb(221, 221, 221)',
            borderLeft: '1px solid rgb(221, 221, 221)',
            borderRight: '1px solid rgb(221, 221, 221)',
            backgroundColor: theme.light.grid.color1
        },
        '& .p-inputtext.p-component.p-column-filter': {
            '&:hover': {
                backgroundColor: theme.light.secondary,
                color: theme.light.text.primary,
                border: '1px solid ' + theme.light.accent.brightness(10)
            },
            borderBottom: '1px solid rgb(221, 221, 221)',
            borderTop: '1px solid rgb(221, 221, 221)',
            borderLeft: '1px solid rgb(221, 221, 221)',
            borderRight: '1px solid rgb(221, 221, 221)',
            backgroundColor: theme.light.grid.color1
        },
        '& .p-contextmenu': {
            padding: '10px',
            borderRadius: '8px',
            backgroundColor: theme.light.primary,
            boxShadow: '0px 4px 8px ' + theme.light.header.shadow
        },
        '& .p-menuitem': {
            paddingTop: '5px',
            paddingBottom: '5px'
        },
        '& .p-menuitem.p-menuitem-active': {
            backgroundColor: theme.light.grid.color1
        },
        '& .p-checkbox .p-checkbox-box': {
            width: '1.125em',
            height: '1.125em',
            lineHeight: '1.125em',
            borderRadius: '2px',
            textAlign: 'center',
            border: '1px solid',
            padding: '0px 18px 18px 0px'
        },
        '& .p-radiobutton .p-radiobutton-box': {
            width: '1.125em',
            height: '1.125em',
            lineHeight: '1.125em',
            borderRadius: '100%',
            textAlign: 'center',
            position: 'relative',
            border: '1px solid',
            padding: '10px'
        },
        '& .p-paginator-page.p-link.p-highlight': {
            textDecoration: 'underline',
            fontWeight: 'bold'
        },
        '& .p-paginator .p-link.p-disabled': {
            opacity: 0.4
        },
        [theme.darkTheme]: {
            '& .p-datatable-scrollable-heade': {
                background:
                    'linear-gradient(180deg, ' +
                    theme.dark.grid.color1 +
                    ' 0%, ' +
                    theme.dark.grid.color2 +
                    ' 80%, ' +
                    theme.dark.grid.color3 +
                    ' 100%)',
                color: theme.dark.text.primary
            },
            '& .p-datatable-row:nth-of-type(2n+1)': {
                backgroundColor: theme.dark.grid.even,
                borderTop: '1px solid ' + theme.dark.primary,
                borderBottom: '1px solid ' + theme.dark.primary
            },
            '& .p-datatable-row:nth-of-type(2n)': {
                backgroundColor: theme.dark.grid.odd
            },
            '& .p-paginator .p-link': {
                color: theme.dark.text.primary
            }
        },
        '& .p-datatable-tbody > tr:nth-child(even)': {
            backgroundColor: `${theme.light.grid.even} !important`,
            [theme.darkTheme]: {
                backgroundColor: `${theme.dark.grid.even} !important`
            }
        },
        '& .p-datatable-tbody > tr:nth-child(odd)': {
            backgroundColor: `${theme.light.grid.odd} !important`,
            [theme.darkTheme]: {
                backgroundColor: `${theme.dark.grid.odd} !important`
            }
        },
        '& .p-datatable-scrollable-wrapper': {
            borderRadius: '4px',
            overflow: 'hidden',
            border: 'solid 1px ' + theme.light.text.variant.secondary,
            [theme.darkTheme]: {
                border: 'solid 1px ' + theme.dark.text.variant.secondary
            }
        },
        '& .p-datatable-wrapper': {
            maxHeight: 'calc(100vh - 280px)' + theme.important,
            borderRadius: '4px',
            overflow: 'auto',
            border: 'solid 1px ' + theme.light.text.variant.secondary,
            [theme.darkTheme]: {
                border: 'solid 1px ' + theme.dark.text.variant.secondary
            }
        },
        '& .p-datatable-scrollable-body': {
            height: 'calc(100vh - 280px)'
        },
        '& .p-datatable-thead tr': {
            height: 40
        },
        '& .p-paginator': {
            padding: '0.4em',
            fontSize: '1.2rem'
        },
        '& .p-paginator-element': {
            minWidth: '2.5rem',
            height: '2.5rem',
            fontSize: '1.2rem',
            borderRadius: 4,
            margin: '0 2px',
            '&:hover': {
                backgroundColor: theme.light.accent.opacity(0.25),
                color: theme.light.text.primary,
                border: '1px solid ' + theme.light.accent.opacity(0.25),
                [theme.darkTheme]: {
                    backgroundColor: theme.dark.accent.opacity(0.25),
                    color: theme.dark.text.primary,
                    border: '1px solid ' + theme.dark.accent.opacity(0.25)
                }
            }
        },
        '& .p-paginator-element span': {
            fontSize: '1.2rem'
        },
        '& .p-paginator .p-paginator-pages .p-paginator-page.p-highlight': {
            backgroundColor: theme.light.accent.brightness(30),
            color: theme.light.text.primary,
            [theme.darkTheme]: {
                color: theme.dark.text.primary,
                backgroundColor: theme.dark.accent.brightness(-20)
            }
        },
        '& .checkboxDisabled  .p-checkbox .p-checkbox-box': {
            border: `1px solid ${theme.palette.grey['A200']}`,
            opacity: '0.5',
            cursor: 'default'
        },
        '& .p-checkbox .p-checkbox-icon': {
            cursor: 'default',
            display: 'block !important'
        },
        '& .p-checkbox-box': {
            display: 'block !important'
        },
        '& thead.p-datatable-thead': {
            background: 
                'linear-gradient(180deg, ' +
                theme.light.grid.color1 +
                ' 0%, ' +
                theme.light.grid.color2 +
                ' 80%, ' +
                theme.light.grid.color3 +
                ' 100%)',
            [theme.darkTheme]: {
                background: 
                    'linear-gradient(180deg, ' +
                    theme.dark.grid.color1 +
                    ' 0%, ' +
                    theme.dark.grid.color2 +
                    ' 80%, ' +
                    theme.dark.grid.color3 +
                    ' 100%)'
            }
        },
    },
    '@global': {
        '.p-column-filter-overlay': {
            backgroundColor: theme.light.secondary,
            padding: 10,
            boxShadow: '4px 4px 8px 1px rgb(0 0 0 / 50%)',
            borderRadius: 9,
            [theme.darkTheme]: {
                backgroundColor: theme.dark.secondary + theme.important
            }
        },
        '.p-column-filter-overlay > div': {
            padding: '4px 0'
        },
        '.p-column-filter-overlay .p-dropdown': {
            padding: 6,
            borderRadius: 4,
            backgroundColor: theme.light.primary,
            border: '1px solid ' + theme.light.accent.opacity(0.5),
            [theme.darkTheme]: {
                backgroundColor: theme.dark.primary + theme.important,
                border: '1px solid ' + theme.dark.accent.opacity(0.5)
            },
            '&:hover': {
                backgroundColor: theme.light.accent.opacity(0.25),
                color: theme.light.text.primary,
                border: '1px solid ' + theme.light.accent.opacity(0.25),
                [theme.darkTheme]: {
                    backgroundColor: theme.dark.accent.opacity(0.25),
                    color: theme.dark.text.primary,
                    border: '1px solid ' + theme.dark.accent.opacity(0.75)
                }
            }
        },
        '.p-column-filter-overlay .p-dropdown-panel': {
            backgroundColor: theme.light.primary,
            boxShadow: '4px 4px 8px 1px rgb(0 0 0 / 50%)',
            [theme.darkTheme]: {
                backgroundColor: theme.dark.primary + theme.important
            }
        },
        '.p-column-filter-overlay .p-dropdown-item': {
            padding: 6,
            '&:hover': {
                backgroundColor: theme.light.secondary,
                [theme.darkTheme]: {
                    backgroundColor: theme.dark.secondary + theme.important
                }
            }
        },
        '.p-column-filter-overlay input.p-inputtext': {
            borderRadius: 5,
            padding: 6,
            margin: '6px 0',
            backgroundColor: theme.light.primary,
            border: '1px solid ' + theme.light.accent.opacity(0.5),
            color: theme.light.text.primary,
            [theme.darkTheme]: {
                color: theme.dark.text.primary,
                backgroundColor: theme.dark.primary + theme.important,
                border: '1px solid ' + theme.dark.accent.opacity(0.5)
            },
            '&:hover': {
                backgroundColor: theme.light.accent.opacity(0.25),
                border: '1px solid ' + theme.light.accent.opacity(0.25),
                [theme.darkTheme]: {
                    backgroundColor: theme.dark.accent.opacity(0.25),
                    color: theme.dark.text.primary,
                    border: '1px solid ' + theme.dark.accent.opacity(0.75)
                }
            }
        },
        '.p-column-filter-overlay .p-button': {
            color: theme.light.text.primary,
            width: '100%',
            backgroundColor: theme.light.primary,
            border: '1px solid ' + theme.light.accent.opacity(0.5),
            borderRadius: 4,
            padding: 6,
            [theme.darkTheme]: {
                color: theme.dark.text.primary,
                backgroundColor: theme.dark.primary + theme.important,
                border: '1px solid ' + theme.dark.accent.opacity(0.5)
            },
            '&:hover': {
                backgroundColor: theme.light.accent.opacity(0.25),
                color: theme.light.text.primary,
                border: '1px solid ' + theme.light.accent.opacity(0.25),
                [theme.darkTheme]: {
                    backgroundColor: theme.dark.accent.opacity(0.25),
                    color: theme.dark.text.primary,
                    border: '1px solid ' + theme.dark.accent.opacity(0.75)
                }
            }
        },
        '.p-column-filter-overlay .p-button .p-button-icon': {
            marginRight: 6
        },
        '.p-column-filter-overlay .p-column-filter-constraint': {
            marginBottom: 12
        },
        '.p-column-filter-overlay .p-column-filter-buttonbar .p-button.p-button-sm': {
            width: 'calc(50% - 4px)'
        },
        '.p-column-filter-overlay .p-datatable .p-datatable-tbody>tr>td>div': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        '.p-column-filter-menu-button, .p-column-filter-clear-button': {
            marginLeft: 6,
            color: theme.light.accent.opacity(0.5),
            [theme.darkTheme]: {
                color: theme.dark.accent.opacity(0.5),
            },
        }
    }
});
