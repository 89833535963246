// @ts-nocheck
import { userBusinessUnit } from './utils/helper';
import {
    DrcBackdrop,
    DrcButton,
    DrcDialog,
    DrcEnvironmentMarker,
    DrcImplicitCallback,
    DrcLoading,
    DrcMain,
    DrcMediaQueries,
    DrcPageLogin,
    DrcPageNotFound,
    DrcPanel,
    DrcSecureGroupRoute,
    DrcThemeProvider,
    DrcThemeUtilities,
    DrcTranslate,
    DrcVersionInfo,
    Helmet
} from 'drc/driscolls-react-components/index';
import { DuCommonTranslations, DuThemeUtilities } from '@driscollsinc/driscolls-react-utilities';
import { Middleware } from '@driscollsinc/one-ring';
import Snackbar from '@mui/material/Snackbar';
import { withStyles } from '@mui/styles';
import { OktaAuth, OktaAuthOptions, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { createHashHistory } from 'history';
import React, { lazy, Suspense } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import InitializeApplication from './pages/InitializeApplication';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';
import {
    addInfo,
    hideCommonDialogAction,
    hideLoadingScreenAction,
    serviceWorkerUpdate,
    setCommonDialogAction,
    setPageTitleAction,
    showLoadingScreenAction,
    showReportBackLinkAction,
    showToast
} from './actions/actions';
import { setInitializeRedirectUrl, setMasterDataInitialized, setUserLang } from './actions/MasterActions';
import Header from './components/Header';
import APP_TRANSLATIONS from './data/appTranslations';
import { designColors } from './data/constants';
import LoggingUtilities from './data/LoggingUtilities';
import MasterDataUtilities from './data/MasterDataUtilities';
import { languagesSupportedMap } from './data/translations.js/index.js';
import BackgroundPng from 'Images/Backgrounds/background-blacks.png';
import BackgroundWebP from 'Images/Backgrounds/background-blacks.webp';
import { ReactComponent as DSSelected } from './Images/SVG/DS_Blue.svg';
import { ReactComponent as DS } from './Images/SVG/DS_White.svg';
import { ReactComponent as PPSelected } from './Images/SVG/PP_Blue.svg';
import { ReactComponent as PP } from './Images/SVG/PP_White.svg';
import { ReactComponent as RESelected } from './Images/SVG/RE_Blue.svg';
import { ReactComponent as RE } from './Images/SVG/RE_White.svg';
import { ReactComponent as SWSelected } from './Images/SVG/SW_Blue.svg';
import { ReactComponent as SW } from './Images/SVG/SW_White.svg';
import { ReactComponent as WS } from './Images/SVG/WS-process-white.svg';
import { ReactComponent as WSSelected } from './Images/SVG/WS-process-blue.svg';
import LogOut from './pages/LogOut';
import UserPreference from './pages/UserPreference';
import DOCRoutes from './routes/DOCRoutes';
import V2Routes from './routes/V2Routes';
import { ROUTES } from 'constants/routes';
const Releases = lazy(() => import('./pages/Releases'));
import { DuAuthenticationUtilities, DuDateUtilities } from '@driscollsinc/driscolls-react-utilities';

const allGroups = [...window.config.OKTA_GGS_ADMIN_GROUPS, ...window.config.OKTA_USER_GROUPS, ...window.config.OKTA_ADMIN_GROUPS];
const SITE_NAME = 'GGS';

var styles = (theme) => (
    console.log(theme),
    {
        '@global': {
            '::-webkit-scrollbar': {
                /* width */ width: '8px',
                height: '10px'
            },
            '::-webkit-scrollbar-track': {
                borderRadius: '1px'
            },
            '::-webkit-scrollbar-thumb': {
                backgroundColor: designColors.blue,
                borderRadius: '3px'
            },
            '.MuiButton-root': {
                fontSize: '1rem',
                boxShadow: 'none'
            },
            '.MuiTypography-root': {
                fontFamily: 'Rubik'
            },
            '.MuiTableCell-root': {
                fontFamily: 'Rubik'
            }
        },
        logoLink: {
            left: 'calc(50vw - 70px)',
            position: 'absolute'
        },
        logo: {
            height: 40,
            ['@media ' + DrcMediaQueries.mobileL]: {
                display: 'none'
            }
        },
        logoShowAlways: {
            height: 40
        },
        header: {
            background: 'transparent',
            // backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.green), 97),
            backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(designColors.blue), 97),
            '& .MuiToolbar-root': {
                padding: '0px 12px'
            },
            '& .titleContainer h1': {
                display: 'contents'
            },
            '& .title': {
                textShadow: 'none'
            },
            '@media (prefers-color-scheme: dark)': {
                backgroundColor: 'hsla(341, 57%, 15%, 1)',
                '& .title': {
                    color: 'hsla(341, 57%, 90%, 1)'
                },
                '& .menuButton': {
                    border: '1px solid hsla(341, 57%, 90%, 1)'
                },
                '& .menuButton svg': {
                    color: 'hsla(341, 57%, 90%, 1)'
                }
            },
            '@media print': {
                display: 'none'
            }
        },
        loaderWithMessage: {
            width: '644px',
            left: 'calc(50vw - 322px)',
            top: 'calc(50vh - 60px)',
            position: 'fixed',
            padding: '20px',
            backgroundColor: '#000',
            borderRadius: '10px',
            '@media (prefers-color-scheme: dark)': {
                backgroundColor: '#ddd'
            }
        },
        successToast: {
            '& .MuiSnackbarContent-root': {
                backgroundColor: DuThemeUtilities.DefaultColors.primary.green,
                color: '#fff',
                fontSize: '14px',
                textAlign: 'center'
            },
            '& .MuiSnackbarContent-message': {
                width: '100%'
            }
        },
        errorToast: {
            '& .MuiSnackbarContent-root': {
                backgroundColor: DuThemeUtilities.DefaultColors.primary.red,
                color: '#fff',
                fontSize: '14px',
                textAlign: 'center'
            },
            '& .MuiSnackbarContent-message': {
                width: '100%'
            }
        },
        drawerIcon: {
            width: 20,
            height: 20
        },
        legendClassName: {
            fontFamily: 'Rubik'
        }
    }
);

interface Props {
    history: any;
}
interface State {
    n: number;
    firstName: string;
    lastName: string;
}

class App extends React.Component<Props, State, OktaAuthOptions> {
    constructor(props) {
        super(props);
        this.history = createHashHistory();

        this.oktaAuth = new OktaAuth({
            issuer: window.config.OKTA_ISSUER,
            clientId: window.config.OKTA_CLIENT_ID,
            redirectUri: window.location.origin + '/implicit/callback',
            onAuthRequired: () => {
                this.history.push('/');
            },
            auto_renew: true,
            scopes: ['openid', 'email', 'MulesoftAPIAccess']
        });

        this.oktaAuth.start();

        this.state = {
            // theme: DrcThemeUtilities.CreateSimpleTheme(DuThemeUtilities.DefaultColors.primary.green)
            theme: DrcThemeUtilities.CreateSimpleTheme(designColors.blue)
        };

        //Initializing the Internationalization
        this.props.initialize({
            languages: languagesSupportedMap,
            translation: { ...DuCommonTranslations.COMMON_TRANSLATIONS, ...APP_TRANSLATIONS },
            options: { renderToStaticMarkup }
        });

        this.initializeAnalytics();

        this.props.setInitializeRedirectUrl(window.location.pathname.length > 1 ? window.location.pathname : '/SettlementWorkbench/');

        this.closeCommonDialog = this.closeCommonDialog.bind(this);

        MasterDataUtilities.Register(this.props.setInitializeRedirectUrl, this.props.setMasterDataInitialized);
        LoggingUtilities.Register(this.props.addInfo);
        Middleware.SetProps(this.props.showLoadingScreenAction, this.props.hideLoadingScreenAction, this.props.setCommonDialogAction, false);
        DuThemeUtilities.RegisterTheme(DuThemeUtilities.ButtonThemes.Polymorphism);
    }

    closeCommonDialog() {
        this.props.hideCommonDialog();
    }

    initializeAnalytics = () => {
        const firebaseConfig = {
            apiKey: window.config.ga.apiKey,
            authDomain: window.config.ga.authDomain,
            projectId: window.config.ga.projectId,
            storageBucket: window.config.ga.storageBucket,
            messagingSenderId: window.config.ga.messagingSenderId,
            appId: window.config.ga.appId,
            measurementId: window.config.ga.measurementId
        };

        // Initialize Firebase
        const app = initializeApp(firebaseConfig);
        const analytics = getAnalytics(app);
        window.config.logScreeView = (screen) => {
            logEvent(analytics, 'screen_view', {
                firebase_screen: screen,
                firebase_screen_class: screen
            });
        };
        window.config.logEvent = async (event, eventInfo) => {
            let token = await this.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            let loggedInUserName = DuAuthenticationUtilities.GetUserId(token);
            if (eventInfo && eventInfo.appVersion) {
                eventInfo.appVersion = process.env.REACT_APP_VERSION;
                eventInfo.loggedInUser = loggedInUser;
            }
            logEvent(analytics, event, eventInfo);
        };
        window.config.setGAUserId = (userId) => {
            setUserId(analytics, userId);
        };
    };

    handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.props.showToast('', true);
    };

    restoreOriginalUri = async (_oktaAuth, originalUri) => {
        this.history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    updateServiceWorker = () => {
        const registrationWaiting = this.props.serviceWorkerRegistration.waiting;

        if (registrationWaiting) {
            registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

            registrationWaiting.addEventListener('statechange', (e) => {
                if (e.target.state === 'activated') {
                    this.props.serviceWorkerUpdate();
                    window.location.href = '/logOut';
                }
            });
        }
    };

    componentDidMount() {
        const orgPushState = window.history.pushState;
        window.history.pushState = function () {
            // eslint-disable-next-line prefer-rest-params
            window.config.logScreeView(arguments[2]);
            // eslint-disable-next-line prefer-rest-params
            orgPushState.apply(this, arguments);
        };
        window.onerror = (msg, url, line, col, error) => {
            console.log('Error: ' + msg + '\nurl: ' + url + '\nline: ' + line);
            window.config.logEvent('WebAppError', { error: msg });
        };
    }
    componentDidUpdate(prevProps) {
        const savedUserLang = localStorage.getItem('GGS_UserLang') || 'en-US';
        if (this.props.userLang !== savedUserLang) {
            this.props.setUserLang(savedUserLang);
        } else if (prevProps.userLang !== this.props.userLang) {
            this.props.setActiveLanguage(this.props.userLang);
        } else if (savedUserLang !== this.props.activeLanguage.code) {
            this.props.setActiveLanguage(savedUserLang);
        }
    }

    closeNewVersionDialog = () => {
        this.props.serviceWorkerUpdate();
    };

    render() {
        var { classes } = this.props;
        const isDocUser = userBusinessUnit() === 'DOC';
        const menuItems = isDocUser
            ? [
                  {
                      icon: <SW className={classes.drawerIcon} />,
                      selectedIcon: <SWSelected className={classes.drawerIcon} />,
                      name: isDocUser ? <DrcTranslate>Settlement Workbench</DrcTranslate> : <DrcTranslate>Settlement Workspace</DrcTranslate>,
                      route: '/SettlementWorkbench/',
                      index: 0
                  },
                  {
                      icon: <PP className={classes.drawerIcon} />,
                      selectedIcon: <PPSelected className={classes.drawerIcon} />,
                      name: <DrcTranslate>Pricing Pool</DrcTranslate>,
                      route: '/PricingPool/',
                      index: 1
                  },
                  // { key: 'SP', name: <DrcTranslate>Settlement Pool</DrcTranslate>, route: '/SettlementPool/' },
                  {
                      icon: <DS className={classes.drawerIcon} />,
                      selectedIcon: <DSSelected className={classes.drawerIcon} />,
                      name: <DrcTranslate>Data Setup</DrcTranslate>,
                      items: [
                          { name: <DrcTranslate>Pool Week</DrcTranslate>, route: '/PoolWeek' },
                          { name: <DrcTranslate>Item UOM Conversion</DrcTranslate>, route: '/ItemUomConversion' },
                          { name: <DrcTranslate>Item Shipping Rates</DrcTranslate>, route: '/ItemShippingRates' },
                          { name: <DrcTranslate>Repackaging SKU Mapping</DrcTranslate>, route: '/RepackagingSkuMapping' },
                          { name: <DrcTranslate>Grower Category</DrcTranslate>, route: '/GrowerCategory' },
                          { name: <DrcTranslate>Grower Fixed Price</DrcTranslate>, route: '/GrowerFixedPrice' },
                          { name: <DrcTranslate>Settlement Rate</DrcTranslate>, route: '/SettlementRate' },
                          { name: <DrcTranslate>Customer Code Category</DrcTranslate>, route: '/CustomerCodeCategory' },
                          { name: <DrcTranslate>Grower Percentage</DrcTranslate>, route: '/GrowerPercentage' },
                          { name: <DrcTranslate>VAT Rate Maintenance</DrcTranslate>, route: '/VatRateMaintenance' },
                          { name: <DrcTranslate>Lookup</DrcTranslate>, route: '/LookUp' },
                          { name: <DrcTranslate>Forex Rate</DrcTranslate>, route: '/ConversionRate' },
                          { name: <DrcTranslate>Import Settlement Rate</DrcTranslate>, route: '/ImportSettlementRate' },
                          { name: <DrcTranslate>Journal Account</DrcTranslate>, route: '/JournalAccount' }
                      ],
                      index: 2
                  },
                  {
                      icon: <RE className={classes.drawerIcon} />,
                      selectedIcon: <RESelected className={classes.drawerIcon} />,
                      name: <DrcTranslate>Reports</DrcTranslate>,
                      route: '/Reports/',
                      index: 3
                  }
              ]
            : [
                  {
                      icon: <SW className={classes.drawerIcon} />,
                      selectedIcon: <SWSelected className={classes.drawerIcon} />,
                      name: <DrcTranslate>Dashboard</DrcTranslate>,
                      route: '/Dashboard/',
                      index: 0
                  },
                  {
                      icon: <WS className={classes.drawerIcon} />,
                      selectedIcon: <WSSelected className={classes.drawerIcon} />,
                      name: isDocUser ? <DrcTranslate>Settlement Workbench</DrcTranslate> : <DrcTranslate>Settlement Workspace</DrcTranslate>,
                      route: this.props.userIsAdmin ? '/SettlementWorkbench/Approval' : '/SettlementWorkbench',
                      index: 1
                  },
                  {
                    icon: <PP className={classes.drawerIcon} />,
                    selectedIcon: <PPSelected className={classes.drawerIcon} />,
                    name: <DrcTranslate>Business Rules</DrcTranslate>,
                    route: this.props.userIsAdmin ? ROUTES.BUSINESS_RULES_APPROVE : '/BusinesRules/',
                    index: 2
                },
              ];
        if (isDocUser) {
            menuItems.splice(1, 1);
        }
        return (
            <DrcThemeProvider theme={this.state.theme} fontFamily="Rubik">
                <DrcEnvironmentMarker />
                <Helmet>
                    <title>{this.props.pageTitle.length > 0 ? this.props.pageTitle : SITE_NAME}</title>
                </Helmet>
                <BrowserRouter>
                    <Security oktaAuth={this.oktaAuth} restoreOriginalUri={this.restoreOriginalUri}>
                        <Header allLinks={menuItems} />
                        <Suspense
                            fallback={
                                <DrcMain transparent>
                                    <DrcPanel>
                                        <DrcLoading />
                                    </DrcPanel>
                                </DrcMain>
                            }
                        >
                            <Routes>
                                <Route
                                    path="/"
                                    exact
                                    element={
                                        <DrcPageLogin
                                            poly
                                            setPageTitle={this.props.setPageTitleAction}
                                            backgroundPng={BackgroundPng}
                                            backgroundWebP={BackgroundWebP}
                                        />
                                    }
                                />
                                <Route
                                    path="/InitializeApplication/"
                                    element={
                                        <DrcSecureGroupRoute groupsAllowed={allGroups}>
                                            <InitializeApplication />
                                        </DrcSecureGroupRoute>
                                    }
                                />
                                <Route
                                    path="/Releases/"
                                    element={
                                        <DrcSecureGroupRoute groupsAllowed={allGroups}>
                                            <Releases />
                                        </DrcSecureGroupRoute>
                                    }
                                />
                                <Route path="*" element={isDocUser ? <DOCRoutes /> : <V2Routes />} />
                                <Route
                                    path={ROUTES.OKTA_CALLBACK}
                                    element={
                                        <DrcImplicitCallback
                                            backgroundPng={BackgroundPng}
                                            backgroundWebP={BackgroundWebP}
                                            legendClassName={classes.legendClassName}
                                        />
                                    }
                                />
                                <Route path="/UserPreferences" element={<UserPreference />} />
                                <Route path="/LogOut/" element={<LogOut />} />
                                <Route path={ROUTES.WILDCARD} element={<DrcPageNotFound />} />
                            </Routes>
                        </Suspense>
                    </Security>
                    <DrcVersionInfo allowClick={this.props.isInitialized} />
                    <DrcBackdrop isLoading show={this.props.showLoadingScreen} loadingMessage={this.props.loadingScreenMessage} />
                    <DrcDialog
                        isError={this.props.commonDialog.isMessage}
                        title={this.props.commonDialog.title}
                        open={this.props.commonDialog.show}
                        buttons={
                            <DrcButton poly line isError onClick={this.closeCommonDialog}>
                                OK
                            </DrcButton>
                        }
                    >
                        {this.props.commonDialog.content}
                    </DrcDialog>
                    <DrcDialog
                        title={'New version available'}
                        open={this.props.serviceWorkerUpdated}
                        buttons={
                            <>
                                <DrcButton onClick={this.closeNewVersionDialog}>Close</DrcButton>
                                <DrcButton isPrimary onClick={this.updateServiceWorker}>
                                    Ok
                                </DrcButton>
                            </>
                        }
                    >
                        <div>Refresh to update your app?</div>
                    </DrcDialog>
                </BrowserRouter>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    className={this.props.isSuccess ? classes.successToast : classes.errorToast}
                    open={this.props.toastMessage !== ''}
                    autoHideDuration={5000}
                    onClose={this.handleCloseNotification}
                    message={<span id="message-id">{this.props.toastMessage}</span>}
                />
            </DrcThemeProvider>
        );
    }
}

function mapStateToProps(state) {
    return {
        userIsAdmin: state.rootReducer.userIsAdmin,
        showReportBackLink: state.rootReducer.showReportBackLink,
        showLoadingScreen: state.rootReducer.showLoadingScreen,
        loadingScreenMessage: state.rootReducer.loadingScreenMessage,
        commonDialog: state.rootReducer.commonDialog,
        pageTitle: state.rootReducer.pageTitle,
        isInitialized: state.masterReducer.isInitialized,
        userLang: state.masterReducer.userLang,
        toastMessage: state.rootReducer.toastMessage,
        isSuccess: state.rootReducer.isSuccess,
        serviceWorkerRegistration: state.rootReducer.serviceWorkerRegistration,
        serviceWorkerUpdated: state.rootReducer.serviceWorkerUpdated
    };
}

const mapDispatchToProps = (dispatch) => ({
    hideCommonDialog: () => dispatch(hideCommonDialogAction()),
    showReportBackLinkAction: () => dispatch(showReportBackLinkAction()),
    showLoadingScreenAction: () => dispatch(showLoadingScreenAction()),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction()),
    addInfo: (info) => dispatch(addInfo(info)),
    setUserLang: (lang) => dispatch(setUserLang(lang)),
    setMasterDataInitialized: (isInitialized) => dispatch(setMasterDataInitialized(isInitialized)),
    setInitializeRedirectUrl: (redirectUrl) => dispatch(setInitializeRedirectUrl(redirectUrl)),
    setCommonDialogAction: (title, errors) => dispatch(setCommonDialogAction(title, errors)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    showToast: (message, isSuccess) => dispatch(showToast(message, isSuccess)),
    serviceWorkerUpdate: () => dispatch(serviceWorkerUpdate())
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(App)));
