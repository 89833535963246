import { Call } from '@driscollsinc/driscolls-display-rules';
import { userBusinessUnit } from 'utils/helper';
import { Store } from 'data/store';
const GET_METHIOD: string = 'GET';
const POST_METHIOD: string = 'POST';
const SUCCESS_STATUS_CODE = 200;

const getCall = async (url: string, token: string, options: any): Promise<any> => {
    const state = Store.getState();
    const response = await Call(
        {
            url: url,
            method: GET_METHIOD,
            options: options,
            headers: {
                'x-business-unit': userBusinessUnit(),
                'x-region': userBusinessUnit(),
                'x-language': state?.masterReducer?.userLang?.toLowerCase(),
                'Content-Type': 'application/json; charset=UTF-8'
            }
        },
        token
    );
    return response;
};

const postCall = async (url: string, token: string, options: any, data: any): Promise<any> => {
    const state = Store.getState();
    const response = await Call(
        {
            url: url,
            method: POST_METHIOD,
            options: options,
            headers: {
                'x-business-unit': userBusinessUnit(),
                'x-region': userBusinessUnit(),
                'x-language': state?.masterReducer?.userLang?.toLowerCase(),
                'Content-Type': 'application/json; charset=UTF-8'
            },
            data: data
        },
        token
    );
    return response;
};

export { GET_METHIOD, POST_METHIOD, getCall, postCall, SUCCESS_STATUS_CODE };
