import React from 'react';
import { DrcMain } from 'drc/driscolls-react-components/index';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { designColors, drawerWidth } from '../data/constants';
const useStyles = (props) =>
    makeStyles((theme) => ({
        main: {
            margin: `70px 0 0 ${props.drawerOpen ? drawerWidth : '73'}px`,
            width: 'calc(100vw - 73px)',
            height: '90vh',
            backgroundColor: `${designColors.blue}`,
            [theme.darkTheme]: {
                backgroundColor: `${theme.palette.common.black} !important`
            },
            padding: 0,
            overflow: 'hidden',
            position: 'absolute',
            borderRadius: 0
        },
        container: {
            height: '100%',
            borderRadius: '50px 0 0 0',
            backgroundColor: theme.palette.common.white,
            [theme.darkTheme]: {
                backgroundColor: `${theme.palette.grey['800']} !important`
            },
            overflowY: 'auto',
            padding: '14px',
            paddingTop: '5px'
        }
    }));

function DrcWrapper(props) {
    const classes = useStyles(props)();
    const currentWidth = props.drawerOpen ? `${drawerWidth}px` : '73px';
    const maxWidth = `calc(100% - ${currentWidth})`;
    return (
        <DrcMain className={classes.main} maxWidth={maxWidth} transparent>
            <div className={classes.container}>{props.children}</div>
        </DrcMain>
    );
}

const mapStateToProps = (state) => ({
    drawerOpen: state.rootReducer.drawerOpen
});

export default connect(mapStateToProps, null)(DrcWrapper);
