import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import DrcMain from '../Components/DrcMain';
import DrcPanel from '../Components/DrcPanel';
import DrcLoading from '../Components/DrcLoading';
import { Translate } from 'react-localize-redux';

/**
 *@ignore
 */
const labelStyle = {
    display: 'inline-block',
    margin: '20px 20px 0 20px',
    position: 'relative',
    top: '-15px'
};

/**
 * @description DrcPageLoadingUser is a loading screen for user information and is non customizable
 * @property {*}
 * @class DrcPageLoadingUser
 * @example
 * <DrcPageLoadingUser />
 * @extends {Component}
 */
class DrcPageLoadingUser extends Component {
    /**
     * @description returns a loader for user information
     * @returns {*} loader element
     * @memberof DrcPageLoadingUser
     */
    render() {
        return (
            <DrcMain transparent>
                <Translate>
                    {({ translate }) => (
                        <Helmet>
                            <title>{translate('login.loadingUser', null, { renderInnerHtml: false })}</title>
                        </Helmet>
                    )}
                </Translate>
                <DrcPanel maxWidth="400px" style={{ textAlign: 'center' }}>
                    <h1 style={labelStyle}>
                        <Translate id="login.loadingUserInfo" />
                    </h1>
                    <DrcLoading />
                </DrcPanel>
            </DrcMain>
        );
    }
}

export default DrcPageLoadingUser;
