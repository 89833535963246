import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
// We do not want to send errors on UI, just replacing the text with translationId , which in our case is english text

/**
 *@ignore
 */
const onMissingTranslation = ({ translationId }) => {
    return `${translationId}`;
};

/**
 *@description Translate wrapper to handle missing translations
 * @example <DrcTranslate>{"buttons.download"}</DrcTranslate>
 * @category General
 * @property {children} children  translationId 
 * @class DrcTranslate
 * @extends {Component}
 */
class DrcTranslate extends Component {
    /**
     * @return {*}
     * @memberof DrcTranslate
     */
    render() {
        let translatedText = this.props.children.toString();
        return <Translate id={translatedText} options={{ onMissingTranslation }} />;
    }
}

export default DrcTranslate;
