export const urls = {
    RULE_TYPES: 'ruletypes',
    BUSINESS_RULES: 'businessrules',
    BUSINESS_RULES_HISTORY:'history',
    RULE_DETAILS: 'ruledetail',
    PRODUCING_AREA: 'ggs/producingarea',
    GROWER: 'ggs/grower',
    RANCH: 'ggs/ranch',
    ITEM: 'ggs/item',
    SUBMIT_RULE: 'businessrule',
    SEND_APPROVER_EMAIL: 'approvalemail',
    LOOKUP_DATA: 'lookupvalues',
    SELECTED_TAB: 'selectedTab'
};
