import React from 'react';

/**
 * @description DrcIconLabel is a styled label for icon
 * @example <DrcIconLabel />
 * @category General
 * @tags label icon pretty
 * @property {Object} style custom styles
 * @property {*} titleStyle custom styles for children
 * @property {*} icon icon
 * @property {children} children will be shown as children
 * @class DrcIconLabel
 * @extends {React.Component}
 */
class DrcIconLabel extends React.Component {
    /**
     * @return {*}
     * @memberof DrcIconLabel
     */
    render() {
        const { className, style, titleStyle, icon, children } = this.props;

        return (
            <span className={className} style={style}>
                {icon}
                <span style={{ marginLeft: '10px', ...titleStyle }}>{children}</span>
            </span>
        );
    }
}

export default DrcIconLabel;
