import { DuLogger } from '@driscollsinc/driscolls-react-utilities';
var OktaAuth = require('@okta/okta-auth-js');

/**
 * @description Function to allow user SignIn to the application by verifying okta details
 * @param {*} username
 * @param {*} password
 * @param {string} [scopes=['openid', 'email', 'profile', 'MulesoftAPIAccess']]
 */
const SignIn = function(username, password, scopes = ['openid', 'email', 'profile', 'MulesoftAPIAccess']) {
    var authClient = new OktaAuth({
        issuer: window.config.OKTA_ISSUER,
        clientId: window.config.OKTA_CLIENT_ID,
        scopes: scopes,
        redirectUri: `${window.location.origin}/implicit/callback`
    });

    authClient
        .signIn({
            username: username,
            password: password
        })
        .then(function(transaction) {
            if (transaction.status === 'SUCCESS') {
                authClient.token.getWithRedirect({
                    sessionToken: transaction.sessionToken,
                    scopes: scopes,
                    responseType: 'token'
                });
            } else {
                DuLogger.error('We cannot handle the ' + transaction.status + ' status');
                return null;
            }
        })
        .fail(function(err) {
            DuLogger.error(err);
            return null;
        });
};

/**
 * @description Function to get user credentials to check if user has access to the page
 * @param {*} query
 * @return {*}
 */
const GetUsernameAndPasswordFromQuery = function(query) {
    if (query.includes('username') && query.includes('password')) {
        var queryParams = query.split('&');

        if (queryParams.length !== 2) {
            return null;
        }

        var usernameParts = queryParams[0].split('=');
        var passwordParts = queryParams[1].split('=');

        if (usernameParts.length !== 2 || passwordParts.length !== 2) {
            return null;
        }

        var username = window.atob(usernameParts[1]);
        var password = window.atob(passwordParts[1]);

        return { username, password };
    }

    return null;
};

const DrcAuthenticationUtilities = {
    SignIn,
    GetUsernameAndPasswordFromQuery
};

export default DrcAuthenticationUtilities;
