import { DrcSecureGroupRoute } from 'drc/driscolls-react-components/index';
import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import {
    addInfo,
    hideCommonDialogAction,
    hideLoadingScreenAction,
    serviceWorkerUpdate,
    setCommonDialogAction,
    setPageTitleAction,
    showLoadingScreenAction,
    showReportBackLinkAction,
    showToast
} from '../actions/actions';
import { setInitializeRedirectUrl, setMasterDataInitialized, setUserLang } from '../actions/MasterActions';
import ConversionRate from '../pages/DataSetup/ConversionRate/ConversionRate';
import CustomerCodeCategory from '../pages/DataSetup/CustomerCodeCategory/CustomerCodeCategory';
import GrowerCategory from '../pages/DataSetup/GrowerCategory/GrowerCategory';
import GrowerFixedPrice from '../pages/DataSetup/GrowerFixedPrice/GrowerFixedPrice';
import GrowerPercentage from '../pages/DataSetup/GrowerPercentage/GrowerPercentage';
import ImportSettlementRate from '../pages/DataSetup/ImportSettlementRate/ImportSettelementRate';
import ItemShippingRates from '../pages/DataSetup/ItemShippingRates';
import ItemUomConversion from '../pages/DataSetup/ItemUomConversion/ItemUomConversion';
import JournalAccount from '../pages/DataSetup/JournalAccount/JournalAccount';
import LookUp from '../pages/DataSetup/LookUp/LookUp';
import PoolWeek from '../pages/DataSetup/PoolWeek/PoolWeek';
import RepackagingSkuMapping from '../pages/DataSetup/RepackagingSkuMapping/RepackagingSkuMapping';
import SettlementRate from '../pages/DataSetup/SettlementRate/SettlementRate';
import VatRateMaintenance from '../pages/DataSetup/VatRateMaintenance/VatRateMaintenance';
import PricingPool from '../pages/PricingPool';
import Reports from '../pages/Reports/Reports';
import SettlementWorkbenchDetails from '../pages/SettlementWorkbench/SettlementWorkbenchDetails';
import WeeklySettlementStatus from '../pages/WeeklySettlementStatus';
import { userBusinessUnit } from '../utils/helper';

const allGroups = [...window['config'].OKTA_GGS_ADMIN_GROUPS, ...window['config'].OKTA_USER_GROUPS, ...window['config'].OKTA_ADMIN_GROUPS];
class DOCRoutes extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const isDocUser = userBusinessUnit() === 'DOC';
        return (
            <Routes>
                <Route
                    path="/WeeklySettlementStatus/"
                    element={
                        <DrcSecureGroupRoute groupsAllowed={allGroups}>
                            <WeeklySettlementStatus />
                        </DrcSecureGroupRoute>
                    }
                />
                <Route
                    path="/PricingPool/"
                    element={
                        <DrcSecureGroupRoute groupsAllowed={isDocUser ? [] : allGroups}>
                            <PricingPool />
                        </DrcSecureGroupRoute>
                    }
                />
                <Route
                    path="/SettlementWorkbench/"
                    element={
                        <DrcSecureGroupRoute groupsAllowed={allGroups}>
                            <WeeklySettlementStatus />
                        </DrcSecureGroupRoute>
                    }
                />
                <Route
                    path="/Reports/"
                    element={
                        <DrcSecureGroupRoute groupsAllowed={allGroups}>
                            <Reports />
                        </DrcSecureGroupRoute>
                    }
                />
                <Route
                    path="/PoolWeek"
                    element={
                        <DrcSecureGroupRoute groupsAllowed={allGroups}>
                            <PoolWeek />
                        </DrcSecureGroupRoute>
                    }
                />
                <Route
                    path="/LookUp"
                    element={
                        <DrcSecureGroupRoute groupsAllowed={allGroups}>
                            <LookUp />
                        </DrcSecureGroupRoute>
                    }
                />
                <Route
                    path="/ItemUomConversion"
                    element={
                        <DrcSecureGroupRoute groupsAllowed={allGroups}>
                            <ItemUomConversion />
                        </DrcSecureGroupRoute>
                    }
                />
                <Route
                    path="/ItemShippingRates"
                    element={
                        <DrcSecureGroupRoute groupsAllowed={allGroups}>
                            <ItemShippingRates />
                        </DrcSecureGroupRoute>
                    }
                />
                <Route
                    path="/SettlementRate"
                    element={
                        <DrcSecureGroupRoute groupsAllowed={allGroups}>
                            <SettlementRate />
                        </DrcSecureGroupRoute>
                    }
                />
                <Route
                    path="/RepackagingSkuMapping"
                    element={
                        <DrcSecureGroupRoute groupsAllowed={allGroups}>
                            <RepackagingSkuMapping />
                        </DrcSecureGroupRoute>
                    }
                />
                <Route
                    path="/GrowerCategory"
                    element={
                        <DrcSecureGroupRoute groupsAllowed={allGroups}>
                            <GrowerCategory />
                        </DrcSecureGroupRoute>
                    }
                />
                <Route
                    path="/GrowerFixedPrice"
                    element={
                        <DrcSecureGroupRoute groupsAllowed={allGroups}>
                            <GrowerFixedPrice />
                        </DrcSecureGroupRoute>
                    }
                />
                <Route
                    path="/CustomerCodeCategory"
                    element={
                        <DrcSecureGroupRoute groupsAllowed={allGroups}>
                            <CustomerCodeCategory />
                        </DrcSecureGroupRoute>
                    }
                />
                <Route
                    path="/VatRateMaintenance"
                    element={
                        <DrcSecureGroupRoute groupsAllowed={allGroups}>
                            <VatRateMaintenance />
                        </DrcSecureGroupRoute>
                    }
                />
                <Route
                    path="/GrowerPercentage"
                    element={
                        <DrcSecureGroupRoute groupsAllowed={allGroups}>
                            <GrowerPercentage />
                        </DrcSecureGroupRoute>
                    }
                />
                <Route
                    path="/ConversionRate"
                    element={
                        <DrcSecureGroupRoute groupsAllowed={allGroups}>
                            <ConversionRate />
                        </DrcSecureGroupRoute>
                    }
                />
                <Route
                    path="/ImportSettlementRate"
                    element={
                        <DrcSecureGroupRoute groupsAllowed={allGroups}>
                            <ImportSettlementRate />
                        </DrcSecureGroupRoute>
                    }
                />
                <Route
                    path="/JournalAccount"
                    element={
                        <DrcSecureGroupRoute groupsAllowed={allGroups}>
                            <JournalAccount />
                        </DrcSecureGroupRoute>
                    }
                />
                <Route
                    path="/SettlementWorkbench/:poolWeek/Details"
                    element={
                        <DrcSecureGroupRoute groupsAllowed={allGroups}>
                            <SettlementWorkbenchDetails />
                        </DrcSecureGroupRoute>
                    }
                />
            </Routes>
        );
    }
}

function mapStateToProps(state) {
    return {
        showReportBackLink: state.rootReducer.showReportBackLink,
        showLoadingScreen: state.rootReducer.showLoadingScreen,
        loadingScreenMessage: state.rootReducer.loadingScreenMessage,
        commonDialog: state.rootReducer.commonDialog,
        pageTitle: state.rootReducer.pageTitle,
        isInitialized: state.masterReducer.isInitialized,
        userLang: state.masterReducer.userLang,
        toastMessage: state.rootReducer.toastMessage,
        isSuccess: state.rootReducer.isSuccess,
        serviceWorkerRegistration: state.rootReducer.serviceWorkerRegistration,
        serviceWorkerUpdated: state.rootReducer.serviceWorkerUpdated
    };
}

const mapDispatchToProps = (dispatch) => ({
    hideCommonDialog: () => dispatch(hideCommonDialogAction()),
    showReportBackLinkAction: () => dispatch(showReportBackLinkAction()),
    showLoadingScreenAction: () => dispatch(showLoadingScreenAction()),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction()),
    addInfo: (info) => dispatch(addInfo(info)),
    setUserLang: (lang) => dispatch(setUserLang(lang)),
    setMasterDataInitialized: (isInitialized) => dispatch(setMasterDataInitialized(isInitialized)),
    setInitializeRedirectUrl: (redirectUrl) => dispatch(setInitializeRedirectUrl(redirectUrl)),
    setCommonDialogAction: (title, errors) => dispatch(setCommonDialogAction(title, errors)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    showToast: (message, isSuccess) => dispatch(showToast(message, isSuccess)),
    serviceWorkerUpdate: () => dispatch(serviceWorkerUpdate())
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(DOCRoutes));
